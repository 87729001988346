<template>
  <v-container class="container--fluid grid-list-md">
    <DocumentNumberConfirmation
      :confirmDocumentNumberDialog.sync="confirmDocumentNumberDialog"
    />
    <v-row no-gutters>
      <v-col cols="12" class="my-2">
        <span
          class="font-weight-medium text-body-1 primary--text text--darken-1"
          >{{ $t("creditInvoice.creditInvoiceActions") }}</span
        >
        <NextAndPreviousCommon
          :nextId="nextId"
          :prevId="prevId"
          :documentId="creditInvoiceId"
          @idChange="redirectOnNextAndPrevious"
        />
      </v-col>
    </v-row>
    <v-row
      no-gutters
      v-if="creditInvoiceId && !!model.is_locked && model.user_id !== user.id"
    >
      <v-col cols="12" class="my-3">
        <span
          class="font-weight-medium text-body-1 primary--text text--darken-1 error--text"
          >*{{
            $t("common.documentReadOnlyModeNotice", { userId: model.user.name })
          }}</span
        >
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-card class="mx-auto" outlined>
          <v-card-title class="py-1">
            <h5
              v-if="originModel.credit_invoice_document_number"
              style="max-width: 175px"
              :class="
                creditInvoiceId
                  ? 'px-1 text-truncate font-italic primary--text font-weight-bold'
                  : 'primary--text text--darken-1'
              "
              class="font-weight-regular"
            >
              {{
                creditInvoiceId && !originModel.title
                  ? `${originModel.credit_invoice_document_number}.`
                  : $t("creditInvoice.addCreditInvoice") || creditInvoiceId
                  ? originModel.title &&
                    `${originModel.credit_invoice_document_number}. ${originModel.title}`
                  : $t("creditInvoice.addCreditInvoice")
              }}
            </h5>
            <v-spacer />
            <v-menu
              bottom
              v-if="creditInvoiceId"
              :close-on-click="true"
              :offset-y="true"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :color="
                    remarksList && remarksList.length > 0
                      ? 'success'
                      : 'primary'
                  "
                  class="ma-2"
                  outlined
                  small
                  @click="remarksDialog = true"
                  :disabled="
                    accessRight.length < 2 && accessRight.includes('show')
                  "
                >
                  {{ $t("remarks.remarks") }}
                </v-btn>
                <v-btn
                  color="primary"
                  class="ma-2"
                  small
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  :disabled="
                    !valid || (!!model.is_locked && model.user_id !== user.id)
                  "
                >
                  <v-icon left> mdi-menu-down </v-icon>
                  {{ $t("quotation.options") }}
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-if="accessRight.includes('create')"
                  dense
                  class="primary--text"
                  @click="copyInvoice"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small class="primary--text">
                        mdi-content-copy
                      </v-icon>
                      {{ $t("common.copyDocument") }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  dense
                  class="primary--text"
                  @click="actDialog = true"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small class="primary--text"> mdi-pulse </v-icon>
                      {{ $t("quotation.activityLog") }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  dense
                  class="primary--text"
                  @click="attachedDocumentsDialog = true"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small class="primary--text">
                        mdi-file-document
                      </v-icon>
                      {{ $t("employee.attachedDocuments") }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-menu
              bottom
              v-if="creditInvoiceId"
              :close-on-click="true"
              :offset-y="true"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  class="ma-2"
                  small
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  :disabled="
                    !valid || (!!model.is_locked && model.user_id !== user.id)
                  "
                >
                  <v-icon left> mdi-menu-down </v-icon>
                  {{ $t("calendar.calendarEvents") }}
                </v-btn>
              </template>
              <v-list>
                <!-- <LoginGoogle /> -->
                <CommonCalendar
                  :docId="creditInvoiceId"
                  docType="CreditInvoice"
                  :model="model"
                />
              </v-list>
            </v-menu>
            <HtmlToPdf
              v-if="creditInvoiceId"
              class="me-2 mb-1"
              :disabled="
                creditInvoiceItems[0].credit_invoice_id &&
                valid &&
                !(!!model.is_locked && model.user_id !== user.id)
              "
              :model="originModel"
              type="CreditInvoicePDF"
            />
            <v-btn
              color="primary"
              small
              :loading="loading"
              :disabled="
                !valid ||
                (isFieldReadable &&
                  !saveOnlyCategoryStatus &&
                  !(
                    isFieldReadable &&
                    creditInvoiceId &&
                    creditInvoicePaymentsModel &&
                    creditInvoicePaymentsModel.invoiceData.length > 0
                  ) &&
                  ((!!model.is_locked && model.user_id !== user.id) ||
                    (isFieldReadable &&
                      !originModel.accounting_update &&
                      !!(
                        creditInvoiceId && !originModel.customer.book_kiping_no
                      )) ||
                    (isFieldReadable &&
                      !!originModel.accounting_update &&
                      !!(
                        creditInvoiceId && originModel.customer.book_kiping_no
                      )) ||
                    (isFieldReadable &&
                      !!originModel.accounting_update &&
                      !!(
                        creditInvoiceId && !originModel.customer.book_kiping_no
                      )))) ||
                (accessRight.length < 2 && accessRight.includes('show'))
              "
              @click="saveInvoice"
            >
              {{ $t("creditInvoice.save") }}
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-card-text class="text-h5 pa-2">
            <FormSkeleton
              v-if="
                (creditInvoiceId &&
                  Object.keys(creditInvoiceById).length <= 0) ||
                (copiedInvoice && copyFormLoading)
              "
            />
            <v-form
              v-else
              ref="form"
              v-model="valid"
              lazy-validation
              :disabled="accessRight.length < 2 && accessRight.includes('show')"
            >
              <Alert :alert.sync="alert" :message="message" />
              <v-row>
                <v-col cols="6">
                  <v-card class="mx-auto" flat>
                    <v-card-title class="py-1 primary--text">
                      <v-icon color="primary">mdi-account-group-outline</v-icon>
                      <h6 class="ms-2 font-weight-regular">
                        {{ $t("creditInvoice.customerDetail") }}
                      </h6>
                      <v-spacer />
                      <v-icon
                        v-if="customer_id"
                        @click="openCustomerAdditionalDetailModel"
                        color="primary"
                        class="float-end"
                        >mdi-eye-outline</v-icon
                      >
                    </v-card-title>
                    <v-divider />
                    <v-card-text>
                      <v-row :dense="true">
                        <v-col cols="12">
                          <v-combobox
                            class="asterisk"
                            autocomplete="new-password"
                            dense
                            :readonly="isFieldReadable ? true : false"
                            cache-items
                            v-model="selectedCustomer"
                            :items="customerList.data"
                            item-text="name_1"
                            item-value="id"
                            @change="onCustomerChange"
                            flat
                            :rules="customerValid"
                            return-object
                            hide-no-data
                            hide-details="auto"
                            required
                            single-line
                            @focus="
                              documentNextAndPrevious(originModel.customer_id)
                            "
                            @blur="documentNextAndPreviousBlur()"
                            @dblclick="redirectOnEditCustomerPage"
                            :search-input.sync="customerSearch"
                            :label="$t('creditInvoice.field.customer')"
                          >
                            <template v-slot:append-outer>
                              <v-icon
                                color="primary"
                                dense
                                v-if="!isFieldReadable"
                                @click="customerDialog = true"
                              >
                                mdi-magnify
                              </v-icon>
                            </template>
                            <template v-slot:selection="data">
                              <span @dblclick="redirectOnEditCustomerPage">{{
                                `${data.item.id}. ${data.item.name_1}`
                              }}</span>
                            </template>
                            <template v-slot:item="data">
                              <div
                                class="font-weight-medium"
                                style="
                                  font-size: 0.8125rem;
                                  display: flex;
                                  align-item: center;
                                "
                              >
                                {{ `${data.item.id}. ${data.item.name_1}` }}
                              </div>
                            </template>
                            <template v-slot:append-item>
                              <div
                                v-show="hasNextPage"
                                v-intersect="infiniteScroll"
                                ref="load"
                                class="loader text-center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="primary"
                                />
                              </div>
                            </template>
                          </v-combobox>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            class="asterisk"
                            autocomplete="new-password"
                            hide-details="auto"
                            :readonly="isFieldReadable"
                            v-model="model.customer_name"
                            :label="$t('creditInvoice.field.customerName')"
                            :rules="customerNameValid"
                            required
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            :readonly="isFieldReadable"
                            hide-details="auto"
                            v-model="model.address"
                            :label="$t('creditInvoice.field.address')"
                            :rules="[
                              lessThanValidation(
                                $t('creditInvoice.field.address'),
                                $t('common.less'),
                                250,
                                model.address
                              ),
                            ]"
                            required
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            :readonly="isFieldReadable"
                            hide-details="auto"
                            v-model="model.town"
                            :label="$t('creditInvoice.field.town')"
                            :rules="[
                              lessThanValidation(
                                $t('creditInvoice.field.town'),
                                $t('common.less'),
                                100,
                                model.town
                              ),
                            ]"
                            required
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="6">
                  <v-card class="mx-auto" flat>
                    <v-card-title class="py-1 primary--text">
                      <v-icon color="primary"
                        >mdi-file-delimited-outline</v-icon
                      >
                      <h6 class="ms-2 font-weight-regular">
                        {{ $t("creditInvoice.creditInvoiceDetail") }}
                      </h6>
                      <v-spacer />
                    </v-card-title>
                    <v-divider />
                    <v-card-text>
                      <v-row :dense="true">
                        <v-col cols="8">
                          <v-text-field
                            :readonly="isFieldReadable"
                            hide-details="auto"
                            dense
                            v-model="model.title"
                            :label="$t('creditInvoice.field.title')"
                            :rules="titleValid"
                            required
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            dense
                            hide-details="auto"
                            v-model="model.credit_invoice_year"
                            :rules="invoiceYearValid"
                            required
                            :label="$t('creditInvoice.field.year')"
                            readonly
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            :readonly="isFieldReadable"
                            hide-details="auto"
                            v-model="model.customer_order_no"
                            :label="$t('creditInvoice.field.customerOrderNo')"
                            :rules="customerOrderNoValid"
                            required
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-menu
                            v-if="!isFieldReadable"
                            ref="startDateMenu"
                            v-model="startDateMenu"
                            :close-on-content-click="false"
                            :return-value.sync="model.credit_invoice_date"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                hide-details="auto"
                                v-model="formattedCreditInvoice"
                                :label="
                                  $t('creditInvoice.field.creditInvoiceDate')
                                "
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="model.credit_invoice_date"
                              :min="minDate"
                              :max="maxDate"
                              @change="
                                $refs.startDateMenu.save(
                                  model.credit_invoice_date
                                )
                              "
                            >
                              <v-btn
                                small
                                class="primary"
                                @click="$refs.startDateMenu.save(todayDate())"
                                >{{ $t("route.today") }}</v-btn
                              >
                            </v-date-picker>
                          </v-menu>
                          <v-text-field
                            v-else
                            hide-details="auto"
                            v-model="formattedCreditInvoice"
                            :label="$t('creditInvoice.field.creditInvoiceDate')"
                            readonly
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-combobox
                            ref="contactCombo"
                            autocomplete="new-password"
                            :readonly="isFieldReadable"
                            v-model="selectedContact"
                            :items="contactList.data"
                            item-text="name"
                            item-value="id"
                            @change="onContactChange"
                            :clearable="!isFieldReadable"
                            flat
                            return-object
                            hide-no-data
                            hide-details="auto"
                            required
                            single-line
                            :search-input.sync="contactSearch"
                            :label="$t('creditInvoice.field.contact')"
                          >
                            <template v-slot:append-outer>
                              <v-icon
                                class="me-1 mt-1"
                                color="primary"
                                dense
                                v-if="!isFieldReadable"
                                @click="contactDialog = true"
                              >
                                mdi-magnify
                              </v-icon>
                              <v-icon
                                class="mt-1"
                                color="primary"
                                dense
                                v-if="
                                  accessRight.includes('create') &&
                                  customer_id &&
                                  !isFieldReadable
                                "
                                @click="dialog = true"
                              >
                                mdi-plus
                              </v-icon>
                            </template>
                            <template v-slot:item="data">
                              <div
                                class="font-weight-medium"
                                style="
                                  font-size: 0.8125rem;
                                  display: flex;
                                  align-item: center;
                                "
                              >
                                {{ `${data.item.id}. ${data.item.name}` }}
                              </div>
                            </template>
                            <template v-slot:append-item>
                              <div
                                v-show="hasContactNextPage"
                                v-intersect="infiniteScrollOnContact"
                                ref="load"
                                class="loader text-center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="primary"
                                />
                              </div>
                            </template>
                          </v-combobox>
                        </v-col>
                        <v-col cols="4">
                          <v-combobox
                            autocomplete="new-password"
                            :readonly="isFieldReadable"
                            v-model="selectedEmployee"
                            :items="employeeList.data"
                            item-text="name"
                            item-value="id"
                            @change="onEmployeeChange"
                            :clearable="!isFieldReadable"
                            flat
                            return-object
                            hide-no-data
                            hide-details="auto"
                            required
                            single-line
                            @dblclick="redirectOnEditEmployeePage"
                            :search-input.sync="employeeSearch"
                            :label="$t('creditInvoice.field.agent')"
                          >
                            <template v-slot:append-outer>
                              <v-icon
                                class="mt-1"
                                color="primary"
                                dense
                                v-if="!isFieldReadable"
                                @click="employeeDialog = true"
                              >
                                mdi-magnify
                              </v-icon>
                            </template>
                            <template v-slot:item="data">
                              <div
                                class="font-weight-medium"
                                style="
                                  font-size: 0.8125rem;
                                  display: flex;
                                  align-item: center;
                                "
                              >
                                {{ `${data.item.id}. ${data.item.name}` }}
                              </div>
                            </template>
                            <template v-slot:append-item>
                              <div
                                v-show="hasEmployeeNextPage"
                                v-intersect="infiniteScrollOnEmployee"
                                ref="load"
                                class="loader text-center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="primary"
                                />
                              </div>
                            </template>
                          </v-combobox>
                        </v-col>
                        <v-col
                          cols="4"
                          v-if="moreDocumentNumber && !creditInvoiceId"
                        >
                          <v-autocomplete
                            :disabled="isCreditId"
                            hide-details="auto"
                            cache-items
                            :items="documentData"
                            :key="documentData.length"
                            item-text="from_no"
                            item-value="id"
                            flat
                            :rules="!isCreditId ? documentNumberRequired : []"
                            v-model="model.doc_series_number"
                            return-object
                            hide-no-data
                            required
                            single-line
                            :label="$t('documentSeries.selectNumber')"
                          >
                            <template v-slot:item="data">
                              <span>{{
                                `${data.item.series} - ${data.item.from_no}. ${data.item.description}`
                              }}</span>
                            </template>
                            <template v-slot:selection="data">
                              <span>{{
                                `${data.item.series} - ${data.item.from_no}. ${data.item.description}`
                              }}</span>
                            </template>
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="4">
                          <WarehouseInput
                            :isFieldReadable="isFieldReadable"
                            :selectedWarehouseModel.sync="selectedWarehouse"
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12">
                  <v-card class="mx-auto" flat>
                    <v-card-title class="py-1 primary--text">
                      <v-row dense no-gutters>
                        <v-col cols="8" class="d-flex">
                          <v-icon color="primary">mdi-cart-plus</v-icon>
                          <h6
                            class="ms-2 align-self-center font-weight-regular"
                          >
                            {{ $t("creditInvoice.listOfItems") }}
                          </h6>
                        </v-col>
                        <v-col cols="4" class="text-end font-weight-regular">
                          <v-btn
                            v-if="creditInvoiceId"
                            class="me-2"
                            @click="getInvoices('updatedDocuments')"
                            color="primary"
                            outlined
                            small
                            :disabled="
                              !!model.is_locked && model.user_id !== user.id
                            "
                          >
                            {{ $t("receipt.updatedDocuments") }}
                          </v-btn>
                          <v-btn
                            @click="getInvoices(null)"
                            color="primary"
                            outlined
                            small
                            :disabled="!valid || isFieldReadable || isCreditId"
                          >
                            {{ $t("receipt.getInvoices") }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-title>
                    <v-divider />
                    <v-card-text>
                      <v-row :dense="true">
                        <v-col cols="12">
                          <v-simple-table
                            dense
                            class="custom-table elevation-1 item-table"
                            fixed-header
                            height="250"
                          >
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th :style="{ width: '40px' }">
                                    {{ $t("creditInvoice.no") }}
                                  </th>
                                  <th :style="{ width: '220px' }">
                                    {{ $t("creditInvoice.product") }}
                                  </th>
                                  <th :style="{ width: '180px' }">
                                    {{ $t("creditInvoice.itemDescription") }}
                                  </th>
                                  <th :style="{ width: '50px' }">
                                    {{ $t("creditInvoice.quantity") }}
                                  </th>
                                  <th
                                    v-if="
                                      !creditInvoiceId ||
                                      (creditInvoiceId &&
                                        accessRight.includes('financial'))
                                    "
                                    class="text-center"
                                    :style="{ width: '100px' }"
                                  >
                                    {{ $t("creditInvoice.unitPrice") }}
                                  </th>
                                  <th
                                    v-if="
                                      !creditInvoiceId ||
                                      (creditInvoiceId &&
                                        accessRight.includes('financial'))
                                    "
                                    :style="{ width: '50px' }"
                                  >
                                    {{ $t("creditInvoice.lineDiscount") }}
                                  </th>
                                  <th
                                    v-if="!creditInvoiceId"
                                    class="text-center"
                                    :style="{ width: '100px' }"
                                  >
                                    <span
                                      v-if="
                                        ($route.params.invoiceId &&
                                          model.including_vat) ||
                                        ($route.query.returnCertificateId &&
                                          model.including_vat)
                                      "
                                    >
                                      {{
                                        $t("company.field.totalIncludingVAT")
                                      }}
                                    </span>
                                    <span
                                      v-else-if="
                                        ($route.params.invoiceId &&
                                          !model.including_vat) ||
                                        ($route.query.returnCertificateId &&
                                          !model.including_vat)
                                      "
                                    >
                                      {{ $t("creditInvoice.total") }}
                                    </span>
                                    <span
                                      v-else-if="
                                        ($route.params.copiedModel &&
                                          creditInvoiceById.including_vat) ||
                                        ($route.query.quotationId &&
                                          quotationById.including_vat) ||
                                        ($route.query.orderId &&
                                          orderById.including_vat) ||
                                        ($route.query.proformaId &&
                                          proformaById.including_vat) ||
                                        ($route.query.deliveryId &&
                                          deliveryById.including_vat) ||
                                        ($route.query.receiptTaxInvoiceId &&
                                          receiptTaxInvoiceById.including_vat)
                                      "
                                    >
                                      {{
                                        $t("company.field.totalIncludingVAT")
                                      }}
                                    </span>
                                    <span
                                      v-else-if="
                                        ($route.params.copiedModel &&
                                          !creditInvoiceById.including_vat) ||
                                        ($route.query.quotationId &&
                                          !quotationById.including_vat) ||
                                        ($route.query.orderId &&
                                          !orderById.including_vat) ||
                                        ($route.query.proformaId &&
                                          !proformaById.including_vat) ||
                                        ($route.query.deliveryId &&
                                          !deliveryById.including_vat) ||
                                        ($route.query.receiptTaxInvoiceId &&
                                          !receiptTaxInvoiceById.including_vat)
                                      "
                                    >
                                      {{ $t("creditInvoice.total") }}
                                    </span>
                                    <span
                                      v-else-if="!profileById.credit_invoice"
                                    >
                                      {{ $t("creditInvoice.total") }}
                                    </span>
                                    <span
                                      v-else-if="profileById.credit_invoice"
                                    >
                                      {{
                                        $t("company.field.totalIncludingVAT")
                                      }}
                                    </span>
                                  </th>
                                  <th
                                    v-else
                                    class="text-center"
                                    :style="{ width: '100px' }"
                                  >
                                    {{
                                      !model.including_vat
                                        ? $t("creditInvoice.total")
                                        : $t("company.field.totalIncludingVAT")
                                    }}
                                  </th>
                                  <th
                                    class="text-center"
                                    :style="{ width: '80px' }"
                                  >
                                    {{ $t("creditInvoice.action") }}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <template
                                  v-for="(item, index) in creditInvoiceItems"
                                >
                                  <template
                                    v-if="
                                      index === 0 ||
                                      (item.same_doc_id !==
                                        creditInvoiceItems[index - 1]
                                          .same_doc_id &&
                                        item.item_code !== '*')
                                    "
                                  >
                                    <tr
                                      v-if="item.source_document_number"
                                      :key="'new_line_' + item.same_doc_id"
                                      :style="'background-color: #CFD8DC'"
                                    >
                                      <td
                                        :colspan="
                                          $route.query.invoiceId ? 2 : 1
                                        "
                                      ></td>
                                      <td><span>-</span></td>
                                      <td colspan="6">
                                        <span>
                                          <b>{{ $t("order.field.docNo") }}:</b>
                                          {{ item.source_document_number
                                          }}<br />
                                        </span>
                                        <span>
                                          <b
                                            >{{ $t("order.field.docDate") }}:</b
                                          >
                                          {{
                                            item.source_document_date.split(
                                              "T"
                                            )[0]
                                          }}<br />
                                        </span>
                                        <span v-if="item.source_document_title">
                                          <b
                                            >{{
                                              $t("order.field.docTitle")
                                            }}:</b
                                          >
                                          {{ item.source_document_title }}<br />
                                        </span>
                                        <span
                                          v-if="
                                            item.source_document_customer_order_no
                                          "
                                        >
                                          <b
                                            >{{
                                              $t("order.field.customerOrderNo")
                                            }}:</b
                                          >
                                          {{
                                            item.source_document_customer_order_no
                                          }}<br />
                                        </span>
                                      </td>
                                    </tr>
                                  </template>
                                  <tr
                                    style="vertical-align: bottom"
                                    :key="index"
                                    :class="[
                                      'yourRowClass',
                                      index + 1 === creditInvoiceItems.length
                                        ? 'newRow'
                                        : '',
                                      {
                                        disabled:
                                          ($route.params.invoiceId &&
                                            !$route.params.type) ||
                                          ($route.query.returnCertificateId &&
                                            !$route.query.type &&
                                            !model.id),
                                      },
                                      item.item_code === '*' &&
                                      item.doc_discount
                                        ? 'yellow accent-2'
                                        : '',
                                      {
                                        disabled:
                                          item.item_code === '*' &&
                                          item.doc_discount,
                                      },
                                    ]"
                                  >
                                    <td>
                                      {{ index + 1 }}
                                    </td>
                                    <td>
                                      <span
                                        v-if="isFieldReadable"
                                        @dblclick="
                                          redirectOnEditProductPage(
                                            item.item_id
                                          )
                                        "
                                      >
                                        {{ item.item_code }}
                                      </span>
                                      <span v-else class="d-flex">
                                        <product-item-select
                                          :selectedCustomer="selectedCustomer"
                                          :table="'CreditInvoice'"
                                          :key="index"
                                          :itemId="item.item_id"
                                          :item="item"
                                          :index="index"
                                          @onProductChange="onProductChange"
                                        />
                                        <template>
                                          <v-icon
                                            class="mt-1"
                                            color="primary"
                                            dense
                                            @click="
                                              (productDialog = true),
                                                (productDialogIndex = index)
                                            "
                                            :disabled="
                                              accessRight.length < 2 &&
                                              accessRight.includes('show')
                                            "
                                          >
                                            mdi-magnify
                                          </v-icon>
                                        </template>
                                      </span>
                                    </td>
                                    <td>
                                      <v-textarea
                                        v-if="!item.doc_discount"
                                        dense
                                        rows="1"
                                        :readonly="isFieldReadable"
                                        hide-details="auto"
                                        v-model="item.item_description"
                                        :title="item.item_description"
                                        :disabled="
                                          !item.selectedProduct ? true : false
                                        "
                                        :rules="[
                                          lessThanValidation(
                                            $t('creditInvoice.itemDescription'),
                                            $t('common.less'),
                                            4000,
                                            item.item_description
                                          ),
                                        ]"
                                        required
                                      />
                                      <v-textarea
                                        v-else
                                        dense
                                        rows="1"
                                        :readonly="isFieldReadable"
                                        hide-details="auto"
                                        :placeholder="
                                          $t('common.documentDiscount')
                                        "
                                      />
                                    </td>
                                    <td>
                                      <v-text-field
                                        dense
                                        :readonly="isFieldReadable"
                                        hide-details="auto"
                                        v-model="item.quantity"
                                        :disabled="
                                          !item.selectedProduct ? true : false
                                        "
                                        :rules="quantityValid"
                                        required
                                        @input="itemPriceCalculation(index)"
                                      >
                                        <template v-if="item.quantity === 0">
                                          {{ (item.quantity = null) }}
                                        </template>
                                      </v-text-field>
                                    </td>
                                    <td
                                      v-if="
                                        !creditInvoiceId ||
                                        (creditInvoiceId &&
                                          accessRight.includes('financial'))
                                      "
                                    >
                                      <v-text-field
                                        dense
                                        :readonly="isFieldReadable"
                                        :name="'unit_price' + index"
                                        :ref="'unit_price' + index"
                                        :reverse="
                                          locale === 'he' ? false : true
                                        "
                                        hide-details="auto"
                                        v-model="item.unit_price"
                                        :disabled="
                                          !item.selectedProduct ? true : false
                                        "
                                        :rules="unitPriceValid"
                                        required
                                        @input="itemPriceCalculation(index)"
                                      >
                                        <template v-if="item.unit_price === 0">
                                          {{ (item.unit_price = null) }}
                                        </template>
                                      </v-text-field>
                                    </td>
                                    <td
                                      v-if="
                                        !creditInvoiceId ||
                                        (creditInvoiceId &&
                                          accessRight.includes('financial'))
                                      "
                                    >
                                      <v-text-field
                                        dense
                                        :readonly="isFieldReadable"
                                        hide-details="auto"
                                        v-model="item.line_discount"
                                        :disabled="
                                          !item.selectedProduct ? true : false
                                        "
                                        :rules="discountValid"
                                        required
                                        @input="itemPriceCalculation(index)"
                                      >
                                        <template v-slot:append>
                                          <v-icon
                                            class="mt-2"
                                            x-small
                                            color="primary"
                                            >mdi-percent-outline</v-icon
                                          >
                                        </template>
                                        <template
                                          v-if="item.line_discount === 0"
                                        >
                                          {{ (item.line_discount = null) }}
                                        </template>
                                      </v-text-field>
                                    </td>
                                    <td
                                      v-if="
                                        !creditInvoiceId ||
                                        (creditInvoiceId &&
                                          accessRight.includes('financial'))
                                      "
                                      :class="
                                        locale === 'he'
                                          ? 'text-start'
                                          : 'text-end'
                                      "
                                    >
                                      <v-text-field
                                        dense
                                        :readonly="isFieldReadable"
                                        :name="'total_price' + index"
                                        :ref="'total_price' + index"
                                        :reverse="
                                          locale === 'he' ? false : true
                                        "
                                        hide-details="auto"
                                        v-model="item.total"
                                        :disabled="
                                          !item.selectedProduct ? true : false
                                        "
                                        :rules="unitPriceValid"
                                        required
                                        @input="itemPriceCalculation(index)"
                                      >
                                        <template v-if="item.total === 0">
                                          {{ (item.total = null) }}
                                        </template>
                                      </v-text-field>
                                    </td>
                                    <td class="text-center">
                                      <v-row>
                                        <v-col cols="4" class="text-end">
                                          <v-btn
                                            icon
                                            :disabled="
                                              isFieldReadable ||
                                              (accessRight.length < 2 &&
                                                accessRight.includes('show'))
                                            "
                                            color="primary"
                                            small
                                            @click="addRow"
                                          >
                                            <v-icon color="primary">
                                              mdi-plus
                                            </v-icon>
                                          </v-btn>
                                        </v-col>
                                        <v-col cols="4" class="text-end">
                                          <v-btn
                                            icon
                                            :disabled="
                                              isFieldReadable ||
                                              (accessRight.length < 2 &&
                                                accessRight.includes('show')) ||
                                              !item.selectedProduct
                                            "
                                            color="primary"
                                            x-small
                                            class="mt-1"
                                            @click="copyData(index)"
                                          >
                                            <v-icon color="primary">
                                              mdi-content-copy
                                            </v-icon>
                                          </v-btn>
                                        </v-col>
                                        <v-col cols="4" class="text-start">
                                          <v-btn
                                            icon
                                            :disabled="
                                              isFieldReadable ||
                                              (accessRight.length < 2 &&
                                                accessRight.includes('show'))
                                            "
                                            color="error"
                                            small
                                            @click="deleteRow(index)"
                                          >
                                            <v-icon color="error">
                                              mdi-delete-sweep-outline
                                            </v-icon>
                                          </v-btn>
                                        </v-col>
                                      </v-row>
                                    </td>
                                  </tr>
                                </template>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-col>
                      </v-row>
                      <v-row :dense="true" class="pt-6">
                        <v-col cols="8">
                          <v-row :dense="true">
                            <v-col cols="8">
                              <v-row>
                                <v-col cols="6">
                                  <v-radio-group
                                    hide-no-data
                                    hide-details="auto"
                                    dense
                                    single-line
                                    v-model="selectedStatus"
                                    :disabled="isFieldReadable"
                                    readonly
                                    class="text-caption pt-0 mt-3"
                                  >
                                    <template v-slot:label>
                                      <div>
                                        {{ $t("receipt.field.status") }}
                                      </div>
                                    </template>
                                    <v-radio
                                      v-for="statusItem in statusList"
                                      :key="statusItem.id"
                                      :label="`${statusItem.name}`"
                                      :value="statusItem.id"
                                    ></v-radio>
                                  </v-radio-group>
                                  <v-select
                                    class="my-5"
                                    v-model="selectedGroup"
                                    :items="groupValue"
                                    item-text="description"
                                    item-value="id"
                                    return-object
                                    :menu-props="{
                                      bottom: true,
                                      offsetY: true,
                                    }"
                                    :label="
                                      $t('creditInvoice.creditInvoiceStatus')
                                    "
                                    @change="changeCategoryStatus"
                                  />
                                </v-col>
                                <v-col cols="6">
                                  <v-checkbox
                                    dense
                                    :readonly="
                                      !creditInvoiceId ||
                                      originModel.status !== 1 ||
                                      (!!creditInvoiceId &&
                                        originModel.status == 1 &&
                                        originModel.customer.book_kiping_no &&
                                        !!originModel.accounting_update)
                                    "
                                    hide-details="auto"
                                    v-model="model.accounting_update"
                                    @click="
                                      bookkeepingConfirmationDialog =
                                        !!creditInvoiceId &&
                                        originModel.status == 1 &&
                                        !originModel.customer.book_kiping_no &&
                                        !originModel.accounting_update
                                          ? true
                                          : false
                                    "
                                    @click.native.stop="
                                      bookkeepingRecordsDialog =
                                        (originModel.accounting_update &&
                                          originModel.status == 1) ||
                                        originModel.status == 2
                                          ? true
                                          : false
                                    "
                                    :label="
                                      $t('creditInvoice.field.accountUpdate')
                                    "
                                  />
                                  <v-autocomplete
                                    :class="
                                      relatedReturnCertificateId.length > 0
                                        ? 'accent lighten-3'
                                        : ''
                                    "
                                    cache-items
                                    :items="relatedReturnCertificateId"
                                    item-text="related_doc_number"
                                    item-value="related_doc_number"
                                    flat
                                    @change="redirectReturnCertificate"
                                    return-object
                                    hide-no-data
                                    hide-details="auto"
                                    required
                                    single-line
                                    :label="
                                      $t(
                                        'returnCertificate.returnCertificateNo'
                                      )
                                    "
                                  />
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col cols="4">
                              <v-row dense>
                                <v-col cols="12">
                                  <v-textarea
                                    :readonly="isFieldReadable"
                                    hide-details="auto"
                                    dense
                                    rows="7"
                                    v-model="model.remarks"
                                    :label="$t('creditInvoice.field.remarks')"
                                    :rules="[
                                      lessThanValidation(
                                        $t('creditInvoice.field.remarks'),
                                        $t('common.less'),
                                        250,
                                        model.remarks
                                      ),
                                    ]"
                                  />
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-divider class="mx-8" vertical />
                        <v-col
                          cols="3"
                          v-if="
                            !creditInvoiceId ||
                            (creditInvoiceId &&
                              accessRight.includes('financial'))
                          "
                        >
                          <v-row dense>
                            <v-col cols="6" class="font-weight-bold">
                              {{ $t("creditInvoice.total") }}
                            </v-col>
                            <v-col
                              cols="6"
                              class="font-weight-bold"
                              :class="
                                locale === 'he' ? 'text-start' : 'text-end'
                              "
                            >
                              {{ numberWithCommas(model.total) }}
                            </v-col>
                          </v-row>
                          <v-row dense>
                            <v-col cols="6">
                              <v-row dense no-gutters>
                                <v-col cols="6">
                                  {{ $t("creditInvoice.field.discount") }}
                                </v-col>
                                <v-col cols="6">
                                  <v-text-field
                                    :readonly="isFieldReadable"
                                    height="18"
                                    class="ma-0 pa-0"
                                    hide-details="auto"
                                    dense
                                    :rules="discountValid"
                                    @input="invoicePriceCalculation"
                                    v-model="model.discount_percentage"
                                  >
                                    <template v-slot:append>
                                      <v-icon x-small color="primary"
                                        >mdi-percent-outline</v-icon
                                      >
                                    </template>
                                  </v-text-field>
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col
                              cols="6"
                              :class="
                                locale === 'he' ? 'text-start' : 'text-end'
                              "
                            >
                              {{ numberWithCommas(model.discount) }}
                            </v-col>
                          </v-row>
                          <v-row dense>
                            <v-col cols="6" class="font-weight-bold">
                              {{ $t("creditInvoice.field.afterDiscount") }}
                            </v-col>
                            <v-col
                              cols="6"
                              class="font-weight-bold"
                              :class="
                                locale === 'he' ? 'text-start' : 'text-end'
                              "
                            >
                              {{ numberWithCommas(model.after_discount) }}
                            </v-col>
                          </v-row>
                          <v-row dense>
                            <v-col cols="6">
                              <v-row dense no-gutters>
                                <v-col cols="6">
                                  {{ $t("creditInvoice.field.vat") }}
                                </v-col>
                                <v-col cols="6">
                                  <v-text-field
                                    :readonly="isFieldReadable"
                                    :disabled="includeVatCharge ? false : true"
                                    height="18"
                                    class="ma-0 pa-0"
                                    hide-details="auto"
                                    dense
                                    :rules="vatPrecentageValid"
                                    @input="invoicePriceCalculation"
                                    v-model="model.vat_percentage"
                                  >
                                    <template v-slot:append>
                                      <v-icon x-small color="primary"
                                        >mdi-percent-outline</v-icon
                                      >
                                    </template>
                                  </v-text-field>
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col
                              cols="6"
                              :class="
                                locale === 'he' ? 'text-start' : 'text-end'
                              "
                            >
                              {{ numberWithCommas(model.vat) }}
                            </v-col>
                          </v-row>
                          <v-row dense>
                            <v-col cols="6" class="font-weight-bold">
                              {{ $t("creditInvoice.field.grandTotal") }}
                            </v-col>
                            <v-col
                              cols="6"
                              class="font-weight-bold"
                              :class="
                                locale === 'he' ? 'text-start' : 'text-end'
                              "
                            >
                              {{ numberWithCommas(model.grand_total) }}
                              <v-icon @click="editGrandTotal = true">
                                mdi-pencil-circle
                              </v-icon>
                            </v-col>
                          </v-row>
                          <v-row
                            dense
                            v-if="editGrandTotal || model.grand_total_requestd"
                          >
                            <v-col cols="6">
                              <v-row dense no-gutters>
                                <v-col cols="6" class="font-weight-bold">
                                  {{ $t("company.field.grandTotalRequested") }}
                                </v-col>
                                <v-col cols="6">
                                  <v-text-field
                                    height="18"
                                    :readonly="isFieldReadable"
                                    class="ma-0 pa-0"
                                    hide-details="auto"
                                    dense
                                    :rules="grandTotalRequestedValid"
                                    @change="grandTotalRequested"
                                    v-model="model.grand_total_requestd"
                                  />
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col
                              cols="6"
                              class="font-weight-bold"
                              :class="
                                locale === 'he' ? 'text-start' : 'text-end'
                              "
                            >
                              {{ numberWithCommas(model.grand_total_requestd) }}
                              <v-icon @click="cancelGrandTotalRequested">
                                mdi-cancel
                              </v-icon>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <v-overlay :z-index="999" v-if="isProgressCircular">
        <v-icon size="64" class="hourglass-icon" color="primary"
          >mdi-timer-sand-paused</v-icon
        >
      </v-overlay>
      <ActivityLogTableDialog
        :actDialog.sync="actDialog"
        :docId.sync="creditInvoiceId"
        docType="CreditInvoice"
        docMode="sales"
        docItems="CreditInvoiceItem"
        docEmailSent="CreditInvoiceEmailSent"
      />
      <BookkeepingRecordsDialog
        :bookkeepingRecordsDialog.sync="bookkeepingRecordsDialog"
        type="CreditInvoice"
        :docId.sync="originModel.credit_invoice_document_number"
        docMode="sales"
      />
      <ContactDialog :dialog.sync="dialog" :customer_id.sync="customer_id" />
      <ProductDialog
        @onProductChange="onProductChange"
        v-if="customer_id"
        :dialog.sync="productDialog"
        :productDialogIndex="productDialogIndex"
      />
      <CustomerDetail
        :model.sync="customerAdditionalDetail"
        :isFieldReadable="isFieldReadable"
        @saveCustomerAdditionalDetail="saveCustomerAdditionalDetail"
        :customerDetailDialog.sync="customerDetailDialog"
      />
      <EmployeeDialog
        @onEmployeeChange="onEmployeeChange"
        :dialog.sync="employeeDialog"
      />
      <CustomerDialog
        @onCustomerChange="onCustomerChange"
        :dialog.sync="customerDialog"
      />
      <ContactListDialog
        v-if="customer_id"
        @onContactChange="onContactChange"
        :dialog.sync="contactDialog"
        :customerId.sync="customer_id"
      />
      <!-- dialog for select invoice -->
      <receipt-invoices
        :creditInvoicePaymentsDialog.sync="creditInvoicePaymentsDialog"
        :creditInvoicePaymentsModel.sync="creditInvoicePaymentsModel"
        :saveButtonEnable.sync="saveButtonEnable"
        :creditInvoiceId="creditInvoiceId"
        :updatedButton="type"
        :customerId="customer_id"
        :grandTotal="model.grand_total"
        :paidTotal.sync="paidTotal"
        :creditInvoicePaymentsType.sync="creditInvoicePaymentsType"
        :updatedDocuments="updatedDocuments"
        :accessRight="accessRight"
      />
      <BookkeepingConfirmationDialog
        :dialog.sync="bookkeepingConfirmationDialog"
        :bookkeepingConfirmation="bookkeepingConfirmation"
      />
      <RemarksDialog
        :dialog.sync="remarksDialog"
        :status="originModel.status"
        :docId="creditInvoiceId"
        :docType="41"
      />
      <confirmation-dialog
        :dialog.sync="confirmationDialogStatus"
        :delete_item.sync="delete_item"
        @deleteItem="deleteItem"
      />
      <AttachedDocumentsDialog
        :docId="creditInvoiceId"
        docType="CreditInvoice"
        :dialog.sync="attachedDocumentsDialog"
      />
      <PeriodClosureConfirmationDialog
        :dialog.sync="periodClosureConfirmationDialog"
        :periodClosureConfirmation="periodClosureConfirmation"
      />
      <CommonCopyAndCreateDialog
        v-if="copyAndCreateDialog"
        :dialog.sync="copyAndCreateDialog"
        :modelData="model"
        type="Credit Invoice"
      />
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { changedFormData } from "@/utils";
import FormSkeleton from "@/components/skeleton/FormSkeleton";
import Alert from "@/components/Alert";
import ContactDialog from "../customer/Steps/ContactDialog.vue";
import ProductDialog from "@/components/moduleTableDialog/Dialog/ProductDialog";
import ProductItemSelect from "../../../components/ProductItemSelect.vue";
import CustomerDialog from "@/components/moduleTableDialog/Dialog/CustomerDialog";
import ActivityLogTableDialog from "@/components/activityLog/Dialogs/ActivityLogTableDialog.vue";
import BookkeepingRecordsDialog from "@/components/bookkeepingRecords/Dialogs/BookkeepingRecordsDialog.vue";
import CustomerDetail from "@/components/moduleTableDialog/Dialog/CustomerDetail";
import EmployeeDialog from "@/components/moduleTableDialog/Dialog/EmployeeDialog";
import ContactListDialog from "@/components/moduleTableDialog/Dialog/ContactListDialog";
import HtmlToPdf from "@/components/generatePDF/HtmlToPdf";
import ReceiptInvoices from "./Dialog/ReceiptInvoices";
import {
  formatDateDDMMYYYY,
  parseDateYYYYMMDD,
  todayDate,
  getAuxiliaryZtableValueByName,
} from "../../../utils";
import BookkeepingConfirmationDialog from "@/components/BookkeepingConfirmationDialog";
import RemarksDialog from "@/components/RemarksDialog";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import AttachedDocumentsDialog from "@/components/AttachedDocumentsDialog";
import DocumentNumberConfirmation from "@/components/DocumentNumberConfirmation";
import PeriodClosureConfirmationDialog from "@/components/PeriodClosureConfirmationDialog";
import CommonCopyAndCreateDialog from "@/components/CommonCopyAndCreateDialog";
import NextAndPreviousCommon from "@/components/NextAndPreviousCommon";
import WarehouseInput from "@/components/WarehouseInput";
import CommonCalendar from "@/components/CommonCalendar";
import { validationMixin } from "@/mixins/validationMixin";
import { intersectionMixin } from "@/mixins/intersectionMixin";
import { searchMixin } from "@/mixins/searchMixin";

export default {
  name: "CreditInvoiceAction",
  components: {
    FormSkeleton,
    Alert,
    ContactDialog,
    ProductDialog,
    CustomerDetail,
    ActivityLogTableDialog,
    BookkeepingRecordsDialog,
    EmployeeDialog,
    CustomerDialog,
    ContactListDialog,
    HtmlToPdf,
    ProductItemSelect,
    ReceiptInvoices,
    BookkeepingConfirmationDialog,
    RemarksDialog,
    ConfirmationDialog,
    AttachedDocumentsDialog,
    DocumentNumberConfirmation,
    PeriodClosureConfirmationDialog,
    CommonCopyAndCreateDialog,
    NextAndPreviousCommon,
    WarehouseInput,
    CommonCalendar,
  },
  mixins: [validationMixin, intersectionMixin, searchMixin],
  data() {
    return {
      message: "",
      modeltotalstore: 0.0,
      customerDetailDialog: false,
      alert: false,
      loading: false,
      valid: false,
      setOrderStatus: null,
      customerAdditionalDetail: {},
      model: {
        status: 0,
        total: 0.0,
        discount: 0.0,
        after_discount: 0.0,
        vat: 0.0,
        grand_total: 0.0,
        vat_percentage: 0.0,
        credit_invoice_date: "",
      },
      originModel: {},
      selectedCustomer: null,
      customerDialog: false,
      selectedContact: null,
      contactDialog: false,
      selectedEmployee: null,
      employeeDialog: false,
      actDialog: false,
      startDateMenu: false,
      includeVatCharge: false,
      customerOrderNoRequired: false,
      creditInvoiceItems: [
        {
          selectedProduct: null,
          unit_price: null,
          item_description: null,
          total: null,
        },
      ],
      customer_id: null,
      customerPage: 1,
      customerLimit: 10,
      contactSearch: "",
      contactPage: 1,
      contactLimit: 10,
      employeePage: 1,
      employeeLimit: 10,
      dialog: false,
      productDialog: false,
      productDialogIndex: null,
      selectedStatus: 0,
      copyFormLoading: true,
      creditInvoicePaymentsType: null,
      creditInvoicePaymentsModel: {
        invoiceData: [],
      },
      creditInvoicePaymentsDialog: false,
      bookkeepingRecordsDialog: false,
      paidTotal: null,
      updatedDocuments: null,
      nextId: null,
      prevId: null,
      formattedCreditInvoice: "",
      bookkeepingConfirmationDialog: false,
      remarksDialog: false,
      confirmationDialogStatus: false,
      delete_item: null,
      type: null,
      groupValue: [],
      selectedGroup: null,
      attachedDocumentsDialog: false,
      saveOnlyCategoryStatus: false,
      itemDiscount: null,
      confirmDocumentNumberDialog: false,
      moreDocumentNumber: false,
      documentData: [],
      editGrandTotal: false,
      relatedReturnCertificateId: [],
      periodClosureConfirmationDialog: false,
      saveButtonEnable: false,
      isProgressCircular: false,
      selectedWarehouse: null,
      copyAndCreateDialog: false,
    };
  },
  computed: {
    statusList() {
      return [
        { name: this.$t("creditInvoice.field.open"), id: 0 },
        { name: this.$t("creditInvoice.field.finished"), id: 1 },
        { name: this.$t("creditInvoice.field.canceled"), id: 2 },
      ];
    },
    invoiceYearValid() {
      return [(v) => !!v || this.$t("formRules.invoiceYearRequired")];
    },
    isCreditId() {
      return this.creditInvoiceId ? true : false;
    },
    discountValid() {
      return [
        (v) =>
          !v ||
          /^\d{0,8}(\.\d{1,4})?$/.test(v) ||
          this.$t("formRules.spaceValidValue"),
        (v) =>
          !v ||
          (v && String(v).length <= 12) ||
          this.$t("formRules.lengthValidMessage", {
            fieldName: this.$t("quotation.field.discount"),
            type: this.$t("common.less"),
            size: 12,
          }),
      ];
    },
    creditInvoiceId() {
      return this.$route.params.credit_invoice_id;
    },
    copiedInvoice() {
      return this.$route.params.copiedModel;
    },
    ...mapGetters({
      creditInvoiceById: "creditInvoice/creditInvoiceById",
      locale: "locale",
      profileById: "profile/profileById",
      employeeById: "employee/employeeById",
      customerList: "customer/customerList",
      contactList: "contact/contactList",
      employeeList: "employee/employeeList",
      remarksList: "remarks/remarksList",
      creditInvoiceQuery: "creditInvoice/creditInvoiceQuery",
      accessRight: "accessRight",
      user: "user",
      moreThanOneUserLogout: "moreThanOneUserLogout",
      invoiceById: "invoice/invoiceById",
      returnCertificateById: "returnCertificate/returnCertificateById",
      bookkeepingConfirmation: "bookkeepingConfirmation",
      periodClosureConfirmation: "multiple/periodClosureConfirmation",
      quotationById: "quotation/quotationById",
      orderById: "order/orderById",
      proformaById: "proforma/proformaById",
      deliveryById: "delivery/deliveryById",
      receiptTaxInvoiceById: "receiptTaxInvoice/receiptTaxInvoiceById",
    }),
    isFieldReadable() {
      return this.model.status == 0 ? false : true;
    },
    minDate() {
      if (this.creditInvoiceId) {
        const date = parseDateYYYYMMDD(this.model.credit_invoice_date)?.split(
          "-"
        )[0];
        return `${date}-01-01`;
      } else {
        return null;
      }
    },
    maxDate() {
      if (this.creditInvoiceId) {
        const date = parseDateYYYYMMDD(this.model.credit_invoice_date)?.split(
          "-"
        )[0];
        return `${date}-12-31`;
      } else {
        return null;
      }
    },
  },
  watch: {
    saveButtonEnable: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) this.saveInvoice();
      },
      deep: true,
    },
    "model.credit_invoice_date"(val) {
      const year = this.model.credit_invoice_year;
      this.formattedCreditInvoice = this.profileById.date_format
        ? val
        : formatDateDDMMYYYY(val);
      this.model.credit_invoice_year = parseDateYYYYMMDD(val).split("-")[0];
      if (year && this.model.credit_invoice_year !== year) {
        this.documentSeriesFunction();
      }
    },
    periodClosureConfirmation: {
      handler: function (val) {
        if (val) {
          this.periodClosureConfirmationDialog = true;
        }
      },
      deep: true,
    },
    customerLimit(newVal, oldVal) {
      if (newVal !== oldVal)
        this.$store.dispatch("customer/GetCustomer", {
          page: 1,
          limit: this.customerLimit,
          order_by: "name_1|asc",
        });
    },
    contactSearch(newVal, oldVal) {
      if (newVal !== oldVal)
        if (this.model.customer_id) {
          this.$store.dispatch("contact/GetContact", {
            page: 1,
            limit: 10,
            where_like: newVal ? "id,name|" + newVal : newVal,
            where_and:
              "record_type_number,record_type|" +
              this.model.customer_id +
              ",customers",
            order_by: "name|asc",
          });
        }
    },
    contactLimit(newVal, oldVal) {
      if (newVal !== oldVal)
        if (this.model.customer_id) {
          this.$store.dispatch("contact/GetContact", {
            page: 1,
            limit: this.contactLimit,
            where_and:
              "record_type_number,record_type|" +
              this.model.customer_id +
              ",customers",
            order_by: "name|asc",
          });
        }
    },
    employeeLimit(newVal, oldVal) {
      if (newVal !== oldVal)
        this.$store.dispatch("employee/GetEmployee", {
          page: 1,
          limit: this.employeeLimit,
          order_by: "name|asc",
        });
    },
    profileById() {
      if (
        Object.keys(this.profileById).length > 0 &&
        !this.$route.params.credit_invoice_id &&
        !this.model.credit_invoice_document_number &&
        !this.$route.query.returnCertificateId &&
        !this.$route.params.invoiceId
      ) {
        this.model.vat_percentage = this.profileById.vat_percentage;
      }
      this.formattedCreditInvoice = this.profileById.date_format
        ? this.model.credit_invoice_date
        : formatDateDDMMYYYY(this.model.credit_invoice_date);
    },
    creditInvoiceById() {
      if (this.$route.params.credit_invoice_id) {
        if (Object.keys(this.creditInvoiceById).length > 0) {
          this.model = Object.assign({}, this.creditInvoiceById);
          if (this.model.credit_invoice_date) {
            this.model.credit_invoice_date = new Date(
              this.model.credit_invoice_date
            )
              .toISOString()
              .substring(0, 10);
          }
          if (this.$route.query.type !== "copy-create") {
            this.relatedReturnCertificateId =
              this.model.relatedReturnCertificate.filter(
                (item) => item.related_model_id
              );
          }
          this.selectedStatus = this.model.status;
          this.originModel = Object.assign({}, this.originModel, this.model);
        }
      } else if (this.$route.params.copiedModel) {
        if (Object.keys(this.creditInvoiceById).length > 0) {
          this.model = Object.assign({}, this.creditInvoiceById);
          delete this.model.created_at;
          delete this.model.id;
          delete this.model.updated_at;
          this.model.accounting_update = false;
          this.model.credit_invoice_year = new Date().getFullYear();
          this.model.credit_invoice_date = new Date()
            .toISOString()
            .substring(0, 10);
          this.model?.creditInvoiceItems?.forEach((element) => {
            delete element.supplied;
            delete element.doc_items_id;
            delete element.inventory;
            delete element.multiple_doc_type;
            delete element.calculated_discount;
            delete element.multiple_doc_type;
          });
          this.originModel = Object.assign({}, this.originModel, this.model);
        }
      }
    },
    returnCertificateById() {
      if (Object.keys(this.returnCertificateById).length > 0) {
        this.model = Object.assign({}, this.model, {
          ...this.returnCertificateById,
          creditInvoiceItems: this.returnCertificateById.ReturnCertificateItems,
        });
        if (this.$route.query.type !== "copy-create") {
          this.creditInvoicePaymentsModel?.invoiceData.push({
            customer_id: this.returnCertificateById.customer_id,
            customer_name: this.returnCertificateById.customer_name,
            return_certificate_date:
              this.returnCertificateById.return_certificate_date,
            return_certificate_id: this.returnCertificateById.id,
            return_certificate_year: this.returnCertificateById.year,
            original_amount: this.returnCertificateById.grand_total,
            amount_paid: this.numberWithCommas(
              this.returnCertificateById.grand_total
            ),
            employee_id: this.returnCertificateById.employee_id,
            original_balance: this.returnCertificateById.balance,
          });
        } else {
          this.creditInvoicePaymentsModel?.invoiceData.push({
            customer_id: this.returnCertificateById.customer_id,
            customer_name: this.returnCertificateById.customer_name,
            return_certificate_date:
              this.returnCertificateById.return_certificate_date,
            return_certificate_id: "",
            return_certificate_year: this.returnCertificateById.year,
            original_amount: this.returnCertificateById.grand_total,
            amount_paid: this.numberWithCommas(
              this.returnCertificateById.grand_total
            ),
            employee_id: this.returnCertificateById.employee_id,
            original_balance: this.returnCertificateById.balance,
          });
        }
        this.model.creditInvoicePaymentsData =
          this.creditInvoicePaymentsModel.invoiceData;
        delete this.model.ReturnCertificateItems;
        delete this.model.return_certificate_date;
        delete this.model.created_at;
        delete this.model.return_certificate_time;
        delete this.model.id;
        delete this.model.delivery_note_id;
        delete this.model.deliveryNote;
        delete this.model.updated_at;
        delete this.model.relatedDeliveryNote;
        delete this.model.is_locked;
        delete this.model.is_multiple;
        delete this.model.is_locked;
        delete this.model.user_id;
        delete this.model.user;
        delete this.model.invoice_year;
        delete this.model.balance;
        delete this.model.return_certificate_company;
        delete this.model.car_no;
        delete this.model.proforma_invoice_id;
        delete this.model.relatedProformaInvoice;
        this.paidTotal = this.returnCertificateById.grand_total;
        this.model.title =
          this.returnCertificateById.return_certificate_company;
        this.model.accounting_update = false;
        this.model.creditInvoiceItems.forEach((element) => {
          delete element.inventory;
          delete element.supplied;
          delete element.created_at;
          delete element.updated_at;
          delete element.calculated_discount;
          if (this.$route.query.type === "copy-create") {
            delete element.multiple_doc_type;
          }
        });
        this.model.credit_invoice_year = new Date().getFullYear();
        this.model.credit_invoice_date = new Date()
          .toISOString()
          .substring(0, 10);
        this.originModel = Object.assign({}, this.originModel, this.model);
      }
    },
    quotationById() {
      if (Object.keys(this.quotationById).length > 0) {
        this.model = Object.assign({}, this.model, {
          ...this.quotationById,
          creditInvoiceItems: this.quotationById.quotationItems,
        });
        this.model.creditInvoicePaymentsData = [];
        delete this.model.quotationItems;
        delete this.model.created_at;
        delete this.model.id;
        delete this.model.quotation_id;
        delete this.model.quotation;
        delete this.model.updated_at;
        delete this.model.is_locked;
        delete this.model.is_multiple;
        delete this.model.is_locked;
        delete this.model.user_id;
        delete this.model.user;
        delete this.model.quotation_date;
        delete this.model.quotation_validity;
        delete this.model.quotationStatus;
        delete this.model.name;
        delete this.model.time_printed;
        delete this.model.order_no;
        delete this.model.invoice_no;
        delete this.model.invoice_year;
        delete this.model.relatedInvoices;
        delete this.model.relatedDocument;
        delete this.model.car_no;
        this.paidTotal = this.quotationById.grand_total;
        this.model.accounting_update = false;
        this.model.creditInvoiceItems.forEach((element) => {
          delete element.inventory;
          delete element.supplied;
          delete element.created_at;
          delete element.updated_at;
        });
        this.model.credit_invoice_year = new Date().getFullYear();
        this.model.credit_invoice_date = new Date()
          .toISOString()
          .substring(0, 10);
        this.originModel = Object.assign({}, this.originModel, this.model);
      }
    },
    orderById() {
      if (Object.keys(this.orderById).length > 0) {
        this.model = Object.assign({}, this.model, {
          ...this.orderById,
          creditInvoiceItems: this.orderById.orderItems,
        });
        this.model.creditInvoicePaymentsData = [];
        delete this.model.orderItems;
        delete this.model.created_at;
        delete this.model.id;
        delete this.model.quotation_id;
        delete this.model.quotation;
        delete this.model.updated_at;
        delete this.model.is_locked;
        delete this.model.is_multiple;
        delete this.model.is_locked;
        delete this.model.user_id;
        delete this.model.user;
        delete this.model.quotation_date;
        delete this.model.date_time_printed;
        delete this.model.delivery_note_id;
        delete this.model.name;
        delete this.model.delivery_date;
        delete this.model.order_no;
        delete this.model.invoice_no;
        delete this.model.invoice_year;
        delete this.model.order_date;
        delete this.model.invoice_id;
        delete this.model.proforma_delivery_note_id;
        delete this.model.is_delivery_noted;
        delete this.model.is_proforma_invoiced;
        delete this.model.orderStatus;
        delete this.model.relatedInvoices;
        delete this.model.relatedDocument;
        delete this.model.car_no;
        this.paidTotal = this.quotationById.grand_total;
        this.model.accounting_update = false;
        this.model.credit_invoice_year = new Date().getFullYear();
        this.model.credit_invoice_date = new Date()
          .toISOString()
          .substring(0, 10);
        this.model.creditInvoiceItems.forEach((element) => {
          delete element.quotation_item_id;
          delete element.inventory;
          delete element.supplied;
          delete element.created_at;
          delete element.updated_at;
          delete element.calculated_discount;
          delete element.multiple_doc_type;
        });
        this.originModel = Object.assign({}, this.originModel, this.model);
      }
    },
    proformaById() {
      if (Object.keys(this.proformaById).length > 0) {
        this.model = Object.assign({}, this.model, {
          ...this.proformaById,
          creditInvoiceItems: this.proformaById.proformaInvoiceItems,
        });
        this.model.creditInvoicePaymentsData = [];
        delete this.model.proformaInvoiceItems;
        delete this.model.created_at;
        delete this.model.id;
        delete this.model.proforma_invoice_year;
        delete this.model.proforma_invoice_date;
        delete this.model.order_id;
        delete this.model.delivery_note_id;
        delete this.model.payment_date;
        delete this.model.balance;
        delete this.model.is_delivery_noted;
        delete this.model.proforma_invoice_document_number;
        delete this.model.proformaInvoicesStatus;
        delete this.model.relatedInvoices;
        delete this.model.relatedDeliveryNote;
        delete this.model.relatedOrder;
        this.paidTotal = this.proformaById.grand_total;
        this.model.accounting_update = false;
        this.model.credit_invoice_year = new Date().getFullYear();
        this.model.credit_invoice_date = new Date()
          .toISOString()
          .substring(0, 10);
        this.model.creditInvoiceItems.forEach((element) => {
          delete element.proforma_invoice_id;
          delete element.proforma_invoice_year;
          delete element.proforma_invoice_item_id;
          delete element.order_item_id;
          delete element.inventory;
          delete element.supplied;
          delete element.created_at;
          delete element.updated_at;
          delete element.calculated_discount;
          delete element.multiple_doc_type;
        });
        this.originModel = Object.assign({}, this.originModel, this.model);
      }
    },
    deliveryById() {
      if (Object.keys(this.deliveryById).length > 0) {
        this.model = Object.assign({}, this.model, {
          ...this.deliveryById,
          creditInvoiceItems: this.deliveryById.deliveryNoteItems,
        });
        this.model.creditInvoicePaymentsData = [];
        this.model.title = this.deliveryById.delivery_company;
        delete this.model.deliveryNoteItems;
        delete this.model.created_at;
        delete this.model.id;
        delete this.model.year;
        delete this.model.delivery_date;
        delete this.model.delivery_time;
        delete this.model.delivery_note_document_number;
        delete this.model.quotation_id;
        delete this.model.order_id;
        delete this.model.delivery_company;
        delete this.model.delivery_no;
        delete this.model.car_no;
        delete this.model.quotation;
        delete this.model.order;
        delete this.model.relatedInvoices;
        delete this.model.relatedProformaInvoicesOrder;
        delete this.model.relatedQuotations;
        delete this.model.deliveryNoteStatus;
        this.paidTotal = this.deliveryById.grand_total;
        this.model.accounting_update = false;
        this.model.credit_invoice_year = new Date().getFullYear();
        this.model.credit_invoice_date = new Date()
          .toISOString()
          .substring(0, 10);
        this.model.creditInvoiceItems.forEach((element) => {
          delete element.delivery_note_id;
          delete element.order_item_id;
          delete element.proforma_invoice_item_id;
          delete element.inventory;
          delete element.supplied;
          delete element.created_at;
          delete element.updated_at;
          delete element.calculated_discount;
          delete element.multiple_doc_type;
        });
        this.originModel = Object.assign({}, this.originModel, this.model);
      }
    },
    invoiceById() {
      if (Object.keys(this.invoiceById).length > 0) {
        if (this.$route.params.type !== "copy-create") {
          const inId = this.invoiceById.id;
          this.invoiceById.invoiceItems = this.invoiceById.invoiceItems.map(
            (item) => {
              return { ...item, inId: inId };
            }
          );
        }
        this.model = Object.assign({}, this.model, {
          ...this.invoiceById,
          creditInvoiceItems: this.invoiceById.invoiceItems,
        });
        if (this.$route.params.type !== "copy-create") {
          this.creditInvoicePaymentsModel?.invoiceData.push({
            customer_id: this.invoiceById.customer_id,
            customer_name: this.invoiceById.customer.name_1,
            payment_date: this.invoiceById.payment_date,
            invoice_date: this.invoiceById.invoice_date,
            invoice_id: this.invoiceById.id,
            invoice_year: this.invoiceById.invoice_year,
            original_amount: this.invoiceById.grand_total,
            original_balance: this.invoiceById.balance,
            amount_paid: this.numberWithCommas(this.invoiceById.grand_total),
            employee_id: this.invoiceById.employee_id,
          });
        } else {
          this.creditInvoicePaymentsModel?.invoiceData.push({
            customer_id: this.invoiceById.customer_id,
            customer_name: this.invoiceById.customer.name_1,
            payment_date: this.invoiceById.payment_date,
            invoice_date: this.invoiceById.invoice_date,
            invoice_id: null,
            invoice_year: this.invoiceById.invoice_year,
            original_amount: this.invoiceById.grand_total,
            original_balance: this.invoiceById.balance,
            amount_paid: this.numberWithCommas(this.invoiceById.grand_total),
            employee_id: this.invoiceById.employee_id,
          });
        }
        this.model.creditInvoicePaymentsData =
          this.creditInvoicePaymentsModel.invoiceData;
        delete this.model.invoiceItems;
        delete this.model.delivery_date;
        delete this.model.created_at;
        delete this.model.id;
        delete this.model.delivery_note_id;
        delete this.model.deliveryNote;
        delete this.model.quotation_id;
        delete this.model.quotation;
        delete this.model.order_id;
        delete this.model.order;
        delete this.model.updated_at;
        delete this.model.relatedDeliveryNote;
        delete this.model.relatedOrder;
        delete this.model.relatedQuotation;
        delete this.model.invoice_date;
        delete this.model.invoice_year;
        delete this.model.invoice_document_number;
        delete this.model.is_locked;
        delete this.model.payment_date;
        delete this.model.is_multiple;
        delete this.model.is_locked;
        delete this.model.relatedCreditInvoices;
        delete this.model.relatedReceipts;
        delete this.model.user_id;
        delete this.model.user;
        delete this.model.balance;
        delete this.model.delivery_company;
        delete this.model.car_no;
        delete this.model.proforma_invoice_id;
        delete this.model.relatedProformaInvoice;
        this.paidTotal = this.invoiceById.grand_total;
        this.model.accounting_update = false;
        this.model.creditInvoiceItems.forEach((element) => {
          delete element.inventory;
          delete element.supplied;
          delete element.created_at;
          delete element.updated_at;
          delete element.calculated_discount;
          if (this.$route.params.type === "copy-create") {
            delete element.multiple_doc_type;
          }
        });
        this.model.credit_invoice_year = new Date().getFullYear();
        this.model.credit_invoice_date = new Date()
          .toISOString()
          .substring(0, 10);
        this.originModel = Object.assign({}, this.originModel, this.model);
      }
    },
    receiptTaxInvoiceById() {
      if (Object.keys(this.receiptTaxInvoiceById).length > 0) {
        this.model = Object.assign({}, this.model, {
          ...this.receiptTaxInvoiceById,
          creditInvoiceItems: this.receiptTaxInvoiceById.receiptTaxInvoiceItems,
        });
        this.model.creditInvoicePaymentsData = [];
        delete this.model.receiptTaxInvoiceItems;
        delete this.model.id;
        delete this.model.receipt_tax_invoice_year;
        delete this.model.receipt_tax_invoice_date;
        delete this.model.quotation_id;
        delete this.model.balance;
        delete this.model.receipt_tax_invoice_payment_date;
        delete this.model.delivery_company;
        delete this.model.order_id;
        delete this.model.receipt_tax_invoice_delivery_date;
        delete this.model.with_holding_tax;
        delete this.model.car_no;
        delete this.model.recipt_tax_invoice_document_number;
        delete this.model.document_number_series;
        delete this.model.receiptTaxInvoicePayments;
        delete this.model.order;
        delete this.model.receiptTaxInvoiceStatus;
        delete this.model.relatedQuotation;
        delete this.model.relatedOrder;
        this.paidTotal = this.receiptTaxInvoiceById.grand_total;
        this.model.accounting_update = false;
        this.model.credit_invoice_year = new Date().getFullYear();
        this.model.credit_invoice_date = new Date()
          .toISOString()
          .substring(0, 10);
        this.model.creditInvoiceItems.forEach((element) => {
          delete element.receipt_tax_invoice_id;
          delete element.order_id;
          delete element.receipt_tax_invoice_year;
          delete element.receipt_tax_invoice_item_id;
          delete element.order_item_id;
          delete element.id;
          delete element.inventory;
          delete element.supplied;
          delete element.created_at;
          delete element.updated_at;
          delete element.calculated_discount;
          delete element.multiple_doc_type;
        });
        this.originModel = Object.assign({}, this.originModel, this.model);
      }
    },
    creditInvoicePaymentsModel() {
      if (this.creditInvoicePaymentsModel) {
        this.model = Object.assign(
          {},
          {
            ...this.model,
            creditInvoicePaymentsData:
              this.creditInvoicePaymentsModel.invoiceData,
          }
        );
      }
    },
    async moreThanOneUserLogout() {
      if (this.moreThanOneUserLogout && this.model.id) {
        await this.$store.dispatch("PostDocumentOpenAction", {
          table: "CreditInvoice",
          id: this.model.id,
          is_locked: false,
        });
        this.$store.dispatch("LogOut");
        this.$router.push({ name: "login" });
      }
    },
    bookkeepingConfirmation: {
      handler: function (val) {
        if (val) {
          this.bookkeepingConfirmationDialog = true;
        }
      },
      deep: true,
    },
  },
  async created() {
    window.addEventListener("beforeunload", this.handler);
  },
  async mounted() {
    this.onMounteUpdated();
    if (this.creditInvoiceId) {
      this.$store.dispatch("remarks/GetRemarks", {
        where: "document_type|" + 41,
        where_and: "document_id|" + this.creditInvoiceId,
      });
    }
    this.selectedEmployee = this.user.name;
  },
  beforeDestroy() {
    if (
      (this.model.id &&
        !this.moreThanOneUserLogout &&
        this.model.is_locked &&
        this.model.user_id == this.user.id) ||
      (this.model.id && !this.model.user_id && !this.moreThanOneUserLogout)
    ) {
      this.$store.dispatch("PostDocumentOpenAction", {
        table: "CreditInvoice",
        id: this.model.id,
        is_locked: false,
      });
    }
    this.$refs.form ? this.$refs.form.reset() : "";
    this.model = {};
    this.customer_id = null;
    this.dialog = false;
    this.$store.commit("customer/SET_CUSTOMER_BY_ID", {});
    this.$store.commit("contact/SET_CONTACT", []);
    this.$store.commit("creditInvoice/SET_CREDIT_INVOICE_BY_ID", {});
    this.$store.commit("customer/SET_CUSTOMER", []);
    this.$store.commit("SET_USER_LOGOUT", false);
    window.removeEventListener("beforeunload", this.handler);
  },
  methods: {
    todayDate: todayDate,
    async handler(event) {
      if (
        (this.model.id &&
          !this.moreThanOneUserLogout &&
          this.model.is_locked &&
          this.model.user_id == this.user.id) ||
        (this.model.id && !this.model.user_id && !this.moreThanOneUserLogout)
      ) {
        await this.$store.dispatch("PostDocumentOpenAction", {
          table: "CreditInvoice",
          id: this.model.id,
          is_locked: false,
        });
      }
      event.preventDefault();
    },
    async documentSeriesFunction() {
      this.documentData = [];
      await this.$store
        .dispatch(
          "documentSeries/GetDocumentSeries",
          `?where_and=type,year|CreditInvoice,${this.model.credit_invoice_year}`
        )
        .then((res) => {
          if (res.length > 1) {
            this.moreDocumentNumber = true;
            this.documentData = res;
          } else {
            this.moreDocumentNumber = false;
          }
        });
      await this.$store
        .dispatch(
          "documentSeries/GetDocumentSeries",
          `?where_and=type,year|CreditInvoice,${this.model.credit_invoice_year}`
        )
        .then((res) => {
          if (res.length > 0) {
            this.confirmDocumentNumberDialog = false;
          } else {
            if (this.creditInvoiceId) {
              this.confirmDocumentNumberDialog = false;
            } else {
              this.confirmDocumentNumberDialog = true;
            }
          }
        });
    },
    redirectReturnCertificate(item) {
      if (item) {
        if (item.related_model_id) {
          this.$router.push({
            path:
              "/returnCertificate/ReturnCertificateAction/" +
              item.related_model_id,
          });
        } else {
          this.$router.push({
            path: "/returnCertificate/ReturnCertificateAction/" + item,
          });
        }
      } else {
        return false;
      }
    },
    async cancelGrandTotalRequested() {
      this.editGrandTotal = false;
      this.model.grand_total_requestd = "";
      this.grandTotalRequested();
    },
    async onMounteUpdated() {
      if (
        this.$route.params.credit_invoice_id ||
        this.$route.params.copiedModel ||
        this.$route.params.invoiceId ||
        this.$route.query.returnCertificateId ||
        this.$route.query.quotationId ||
        this.$route.query.orderId ||
        this.$route.query.proformaId ||
        this.$route.query.deliveryId ||
        this.$route.query.receiptTaxInvoiceId
      ) {
        // invoice edit type
        // Invoice for edit mode
        if (this.$route.params.credit_invoice_id) {
          this.documentNextAndPrevious();
          await this.$store
            .dispatch(
              "creditInvoice/GetCreditInvoiceById",
              this.$route.params.credit_invoice_id
            )
            .then((res) => {
              if (res.credit_invoice_document_number) {
                document.title =
                  document.title + "   " + res.credit_invoice_document_number;
              }
            });
          this.selectedGroup = this.model.status_one;
          this.selectedWarehouse = this.model.warehouse;
          if (!this.model.is_locked && this.$route.params.credit_invoice_id) {
            await this.$store.dispatch("PostDocumentOpenAction", {
              table: "CreditInvoice",
              id: this.$route.params.credit_invoice_id,
              is_locked: true,
            });
          }
        } else if (this.$route.params.copiedModel) {
          // Copy invoice
          this.copyFormLoading = false;
          await this.$store.dispatch(
            "creditInvoice/GetCreditInvoiceById",
            this.$route.params.copiedModel
          );
          this.model.status = 0;
          this.selectedGroup = null;
          this.selectedWarehouse = null;
          this.model.status_one = null;
          this.model.warehouse = null;
        } else if (this.$route.params.invoiceId) {
          this.copyFormLoading = false;
          await this.$store.dispatch(
            "invoice/GetInvoiceById",
            this.$route.params.invoiceId
          );
          this.model.status = 0;
          this.model.including_vat = this.model.including_vat ? 1 : 0;
          this.selectedGroup = null;
          this.selectedWarehouse = null;
          this.model.status_one = null;
          this.model.warehouse = null;
        } else if (this.$route.query.returnCertificateId) {
          this.copyFormLoading = false;
          await this.$store.dispatch(
            "returnCertificate/GetReturnCertificateById",
            this.$route.query.returnCertificateId
          );
          this.model.status = 0;
          this.model.including_vat = this.model.including_vat ? 1 : 0;
          this.selectedGroup = null;
          this.selectedWarehouse = null;
          this.model.status_one = null;
          this.model.warehouse = null;
        } else if (this.$route.query.quotationId) {
          this.copyFormLoading = false;
          await this.$store.dispatch(
            "quotation/GetQuotationById",
            this.$route.query.quotationId
          );
          this.model.status = 0;
          this.model.including_vat = this.model.including_vat ? 1 : 0;
          this.selectedGroup = null;
          this.selectedWarehouse = null;
          this.model.status_one = null;
          this.model.warehouse = null;
        } else if (this.$route.query.orderId) {
          this.copyFormLoading = false;
          await this.$store.dispatch(
            "order/GetOrderById",
            this.$route.query.orderId
          );
          this.model.status = 0;
          this.model.including_vat = this.model.including_vat ? 1 : 0;
          this.selectedGroup = null;
          this.selectedWarehouse = null;
          this.model.status_one = null;
          this.model.warehouse = null;
        } else if (this.$route.query.proformaId) {
          this.copyFormLoading = false;
          await this.$store.dispatch(
            "proforma/GetProformaById",
            this.$route.query.proformaId
          );
          this.model.status = 0;
          this.model.including_vat = this.model.including_vat ? 1 : 0;
          this.selectedGroup = null;
          this.selectedWarehouse = null;
          this.model.status_one = null;
          this.model.warehouse = null;
        } else if (this.$route.query.deliveryId) {
          this.copyFormLoading = false;
          await this.$store.dispatch(
            "delivery/GetDeliveryById",
            this.$route.query.deliveryId
          );
          this.model.status = 0;
          this.model.including_vat = this.model.including_vat ? 1 : 0;
          this.selectedGroup = null;
          this.selectedWarehouse = null;
          this.model.status_one = null;
          this.model.warehouse = null;
        } else if (this.$route.query.receiptTaxInvoiceId) {
          this.copyFormLoading = false;
          await this.$store.dispatch(
            "receiptTaxInvoice/GetReceiptTaxInvoiceById",
            this.$route.query.receiptTaxInvoiceId
          );
          this.model.status = 0;
          this.model.including_vat = this.model.including_vat ? 1 : 0;
          this.selectedGroup = null;
          this.selectedWarehouse = null;
          this.model.status_one = null;
          this.model.warehouse = null;
        }
        if (this.model.employee_id) {
          this.selectedEmployee = {
            id: this.model?.employee?.id,
            name: this.model?.employee?.name,
          };
          this.model.employee_id = this.model?.employee_id;
        }
        this.customer_id = this.model?.customer_id;
        this.model.status_one = null;
        this.selectedCustomer = {
          id: this.model?.customer_id,
          name_1: this.model.customer?.name_1,
          fixed_discount: this.model.customer?.fixed_discount,
        };
        if (this.model.contact) {
          this.selectedContact = {
            id: this.model.contact?.id,
            name: this.model.contact?.name,
          };
        }
        this.includeVatCharge = this.model?.customer?.vat_charge;
        this.customerOrderNoRequired =
          this.model?.customer?.customer_order_no_compulsory;
        this.selectedStatus = this.model.status;
        if (this.model.creditInvoiceItems.length < 1) {
          this.creditInvoiceItems = [
            {
              selectedProduct: null,
              unit_price: null,
              item_description: null,
              total: null,
            },
          ];
        } else {
          this.creditInvoiceItems = [];
        }
        this.model.creditInvoiceItems.forEach((element, index) => {
          delete element["created_at"];
          delete element["updated_at"];
          delete element["remainder"];
          if (this.$route.params.copiedModel) {
            delete element["id"];
            delete element["credit_invoice_id"];
          }
          if (this.$route.params.invoiceId) {
            if (this.$route.params.type === "copy-create") {
              delete element["id"];
              delete element["invoice_id"];
            }
            delete element["invoice_item_id"];
            delete element["invoice_year"];
            delete element["invoice_document_number"];
            delete element["order_id"];
            delete element["order_item_id"];
            delete element["document_number"];
            delete element["document_date"];
            delete element["customer_order_no"];
            delete element["title"];
            delete element["delivery_note_item_id"];
            delete element["delivery_note_id"];
            delete element["invoice_doc_number"];
            delete element["proforma_invoice_id"];
            delete element["proforma_invoice_item_id"];
            delete element["invoice_items_id"];
            delete element["inId"];
          }
          if (this.$route.query.quotationId) {
            delete element["id"];
            delete element["quotation_id"];
          }
          if (this.$route.query.orderId) {
            delete element["id"];
            delete element["order_id"];
          }
          if (this.$route.query.returnCertificateId) {
            if (this.$route.query.type === "copy-create") {
              delete element["id"];
              delete element["return_certificate_id"];
            }
            delete element["delivery_item_id"];
            delete element["document_number"];
            delete element["document_date"];
            delete element["customer_order_no"];
            delete element["title"];
            delete element["delivery_note_id"];
          }
          if (this.$route.query.proformaId) {
            delete element["id"];
          }
          if (this.$route.query.deliveryId) {
            delete element["id"];
          }
          let unit_price_change = this.numberWithCommas(
            element["unit_price"],
            "noFloat"
          );
          if (element["unit_price"] && !element["quantity"]) {
            element = Object.assign({}, { ...element, quantity: 1 });
          }
          if (
            (this.$route.query.returnCertificateId &&
              this.$route.query.type !== "copy-create") ||
            (this.$route.params.invoiceId &&
              this.$route.params.type !== "copy-create")
          ) {
            let total_change_2 = element.remainingQuantity * element.unit_price;
            let total_change_3 = (total_change_2 * element.line_discount) / 100;
            let total_change_4 = total_change_2 - total_change_3;
            let total_price_change = this.numberWithCommas(total_change_4);
            let quantity_change = element["remainingQuantity"]
              ? element["remainingQuantity"]
              : element["quantity"];
            if (element.remainingQuantity) {
              this.creditInvoiceItems.push(
                Object.assign(
                  {},
                  {
                    ...element,
                    quantity: quantity_change,
                    total: total_price_change,
                    unit_price: unit_price_change,
                    index: index,
                    type: "saveCreate",
                  },
                  {
                    selectedProduct: {
                      item_id: element.item_id,
                      item_code: element.item_code,
                    },
                  }
                )
              );
            } else {
              this.creditInvoiceItems.push(
                Object.assign(
                  {},
                  {
                    ...element,
                    unit_price: unit_price_change,
                    index: index,
                    type: "saveCreate",
                  },
                  {
                    selectedProduct: {
                      item_id: element.item_id,
                      item_code: element.item_code,
                    },
                  }
                )
              );
            }
          } else {
            let total_price_change = this.numberWithCommas(
              element["total"],
              "noFloat"
            );
            this.creditInvoiceItems.push(
              Object.assign(
                {},
                {
                  ...element,
                  total: total_price_change,
                  unit_price: unit_price_change,
                  index: index,
                },
                {
                  selectedProduct: {
                    item_id: element.item_id,
                    item_code: element.item_code,
                  },
                }
              )
            );
          }
        });
        // updated documents detail
        if (this.model.creditInvoicePayments?.length > 0) {
          this.updatedDocuments = this.model.creditInvoicePayments;
        }
        if (this.model.status === 0) {
          this.$refs.form.validate();
          if (!this.model.grand_total_requestd) {
            this.invoicePriceCalculation();
          }
        }
      } else {
        if (this.$route.query.customerId) {
          let customer = await this.$store.dispatch(
            "customer/GetCustomerById",
            this.$route.query.customerId
          );
          let customerModel = {};
          this.selectedCustomer = { id: customer.id, name_1: customer.name_1 };
          if (this.$route.query.employeeId) {
            await this.$store.dispatch(
              "employee/GetEmployeeById",
              this.$route.query.employeeId
            );
            this.selectedEmployee = {
              id: this.employeeById.id,
              name: this.employeeById.name,
            };
            this.model.employee_id = this.employeeById.id;
          }
          this.model.customer_id = customer.id;
          this.selectedGroup = null;
          this.selectedWarehouse = null;
          this.model.status_one = null;
          this.model.warehouse = null;
          this.customer_id = customer.id;
          let invoiceData = this.$route.query.invoiceData;
          this.model.customer_name = invoiceData.customer_name;
          customerModel.authorized_dealer_no = invoiceData.authorized_dealer_no;
          customerModel.email = invoiceData.email;
          customerModel.address = invoiceData.address;
          customerModel.town = invoiceData.town;
          customerModel.country = invoiceData.country;
          customerModel.phone_1 = invoiceData.phone_1;
          customerModel.phone_2 = invoiceData.phone_2;
          customerModel.cellular = invoiceData.cellular;
          customerModel.fax = invoiceData.fax;
          this.model = Object.assign({}, this.model, customerModel);
          this.model.status = 0;
          this.customerOrderNoRequired = customer?.customer_order_no_compulsory;
          if (this.model.status === 0) {
            this.$refs.form.validate();
          }
        }
        this.model.credit_invoice_year = new Date().getFullYear();
        this.model.credit_invoice_date = new Date()
          .toISOString()
          .substring(0, 10);
      }
      this.$store.dispatch("profile/GetCompanyById");
      this.saveOnlyCategoryStatus = false;
      let tableNameValue = await getAuxiliaryZtableValueByName(16);
      if (Object.keys(tableNameValue).length > 0) {
        this.groupValue = tableNameValue.ztables.sort((a, b) =>
          a.description.toLocaleLowerCase() > b.description.toLocaleLowerCase()
            ? 1
            : -1
        );
      }
    },
    changeCategoryStatus() {
      if (this.originModel.status === 1) {
        this.saveOnlyCategoryStatus = true;
      }
    },
    async documentNextAndPrevious(customerId = null) {
      if (this.creditInvoiceId) {
        let docPagination = await this.$store.dispatch(
          "GetDocumentPagination",
          {
            type: "credit_invoices",
            customer_id: customerId,
            document_id: Number(this.creditInvoiceId),
          }
        );
        this.nextId = docPagination.nextId ? docPagination.nextId : null;
        this.prevId = docPagination.prevId ? docPagination.prevId : null;
      }
    },
    async documentNextAndPreviousBlur(customerId = null) {
      let self = this;
      setTimeout(async () => {
        if (self.creditInvoiceId) {
          let docPagination = await self.$store.dispatch(
            "GetDocumentPagination",
            {
              type: "credit_invoices",
              customer_id: customerId,
              document_id: Number(self.creditInvoiceId),
            }
          );
          self.nextId = docPagination.nextId ? docPagination.nextId : null;
          self.prevId = docPagination.prevId ? docPagination.prevId : null;
        }
      }, 1000);
    },
    async infiniteScrollOnContact([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.contactLimit += 10;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    async infiniteScrollOnEmployee([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.employeeLimit += 10;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    redirectOnNextAndPrevious(id) {
      if (id) {
        this.$router.push({ path: "/creditInvoice/CreditInvoiceAction/" + id });
      } else {
        return false;
      }
    },
    redirectOnEditCustomerPage() {
      if (this.selectedCustomer) {
        this.$router.push({
          path: "/customer/CustomerAction/" + this.selectedCustomer.id,
        });
      } else {
        return false;
      }
    },
    redirectOnEditEmployeePage() {
      if (this.selectedEmployee && this.selectedEmployee.id) {
        this.$router.push({
          path: "/employee/EmployeeAction/" + this.selectedEmployee.id,
        });
      } else {
        return false;
      }
    },
    redirectOnEditProductPage(itemId) {
      if (itemId) {
        this.$router.push({ path: "/item/ItemAction/" + itemId });
      } else {
        return false;
      }
    },
    callBackFunction() {
      this.$router.push({
        name: "CreditInvoice",
        query: {
          ...this.creditInvoiceQuery,
        },
      });
    },
    copyInvoice() {
      this.copyAndCreateDialog = true;
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.customerLimit += 10;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    numberFromCommas(x) {
      return x ? x.toString().replace(/,/g, "") : null;
    },
    numberWithCommas(x, type = "float") {
      return x
        ? type === "float"
          ? parseFloat(x)
              .toFixed(2)
              .toString()
              .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
          : x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
        : type === "float"
        ? (0).toFixed(2)
        : x;
    },
    onEmployeeChange(item) {
      if (item && typeof item === "object" && Object.keys(item).length > 0) {
        this.selectedEmployee = { id: item.id, name: item?.name };
        this.model.employee_id = item.id;
      } else {
        this.selectedEmployee = null;
        this.model.employee_id = null;
      }
    },
    onContactChange(item) {
      if (item && typeof item === "object" && Object.keys(item).length > 0) {
        this.selectedContact = { id: item.id, name: item?.name };
        this.model.contact_id = item.id;
      } else {
        this.model.contact_id = null;
      }
    },
    openCustomerAdditionalDetailModel() {
      this.customerAdditionalDetail.authorized_dealer_no =
        this.model.authorized_dealer_no;
      this.customerAdditionalDetail.email = this.model.email;
      this.customerAdditionalDetail.country = this.model.country;
      this.customerAdditionalDetail.phone_1 = this.model.phone_1;
      this.customerAdditionalDetail.phone_2 = this.model.phone_2;
      this.customerAdditionalDetail.cellular = this.model.cellular;
      this.customerAdditionalDetail.fax = this.model.fax;
      this.customerDetailDialog = true;
    },
    async saveCustomerAdditionalDetail() {
      this.model.authorized_dealer_no =
        this.customerAdditionalDetail.authorized_dealer_no;
      this.model.email = this.customerAdditionalDetail.email;
      this.model.country = this.customerAdditionalDetail.country;
      this.model.phone_1 = this.customerAdditionalDetail.phone_1;
      this.model.phone_2 = this.customerAdditionalDetail.phone_2;
      this.model.cellular = this.customerAdditionalDetail.cellular;
      this.model.fax = this.customerAdditionalDetail.fax;
      if (this.$route.params.credit_invoice_id) {
        this.isProgressCircular = true;
        await this.$store.dispatch("creditInvoice/EditCreditInvoice", {
          credit_invoice_id: this.$route.params.credit_invoice_id,
          creditInvoice: { ...this.customerAdditionalDetail },
        });
        this.isProgressCircular = false;
      }
    },
    lessThanValidation(fieldValue, typeValue, sizeValue, v) {
      return (
        !v ||
        (v && String(v).length <= sizeValue) ||
        this.$t("formRules.lengthValidMessage", {
          fieldName: fieldValue,
          type: typeValue,
          size: sizeValue,
        })
      );
    },
    async onCustomerChange(customer) {
      if (
        customer &&
        typeof customer === "object" &&
        Object.keys(customer).length > 0
      ) {
        let customerModel = {};
        this.selectedCustomer = {
          id: customer.id,
          name_1: customer?.name_1,
          fixed_discount: customer?.fixed_discount,
        };
        this.selectedContact = null;
        this.model.contact_id = null;
        this.model.customer_id = customer.id;
        this.customer_id = customer.id;
        this.includeVatCharge = customer.vat_charge;
        if (customer.vat_charge) {
          this.model.vat_percentage = this.profileById.vat_percentage;
        } else {
          this.model.vat_percentage = "";
          this.invoicePriceCalculation();
        }
        if (customer.id) {
          await this.$store.dispatch("contact/GetContact", {
            page: 1,
            limit: 10,
            where_and:
              "record_type_number,record_type|" + customer.id + ",customers",
            order_by: "name|asc",
          });
        }
        if (this.profileById.occasional_customer_no !== String(customer.id)) {
          this.model.customer_name = customer?.name_1;
          this.includeVatCharge = customer.vat_charge;
          if (customer.employee_id) {
            customer.employee_id
              ? await this.$store.dispatch(
                  "employee/GetEmployeeById",
                  customer.employee_id
                )
              : "";
            this.selectedEmployee = {
              id: this.employeeById.id,
              name: this.employeeById.name,
            };
            this.model.employee_id = customer.employee_id;
          } else {
            this.selectedEmployee = this.user.name;
          }
          customerModel.authorized_dealer_no = customer.authorized_dealer_no;
          customerModel.email = customer.email;
          customerModel.address = customer.address_1;
          customerModel.town = customer.town_1;
          customerModel.country = customer.country;
          customerModel.phone_1 = customer.phone_1;
          customerModel.phone_2 = customer.phone_2;
          customerModel.cellular = customer.cellular_1;
          customerModel.fax = customer.fax;
          this.invoicePriceCalculation();
        } else {
          this.model.customer_name = "";
          customerModel.authorized_dealer_no = "";
          customerModel.email = "";
          customerModel.address = "";
          customerModel.town = "";
          customerModel.country = "";
          customerModel.phone_1 = "";
          customerModel.phone_2 = "";
          customerModel.cellular = "";
          customerModel.fax = "";
        }
        this.customerOrderNoRequired = customer.customer_order_no_compulsory;
        this.model = Object.assign({}, this.model, customerModel);
        if (this.model.status === 0) {
          this.$refs.form.validate();
        }
      }
    },
    itemPriceCalculation(index) {
      const decimalPoints = this.profileById.decimal_points || 2;
      let item = this.creditInvoiceItems[index];
      let checkUnitPrice = item.unit_price;
      if (checkUnitPrice && this.$refs["unit_price" + index]) {
        const pos =
          this.$refs["unit_price" + index][0].$el.querySelector("input")
            .selectionStart + 1;
        if (
          checkUnitPrice.length !=
          this.numberWithCommas(
            this.numberFromCommas(item.unit_price),
            "noFloat"
          ).length
        ) {
          this.$nextTick(() => {
            this.$refs["unit_price" + index][0]?.$el
              ? (this.$refs["unit_price" + index][0].$el.querySelector(
                  "input"
                ).selectionEnd = pos)
              : "";
          });
        } else {
          this.$nextTick(() => {
            this.$refs["unit_price" + index][0]?.$el
              ? (this.$refs["unit_price" + index][0].$el.querySelector(
                  "input"
                ).selectionEnd = pos - 1)
              : "";
          });
        }
      }

      // price format of total price value
      let checkTotal = item.total;
      if (checkTotal && this.$refs["total_price" + index]) {
        const pos =
          this.$refs["total_price" + index][0].$el.querySelector("input")
            .selectionStart + 1;
        if (
          checkTotal.length !=
          this.numberWithCommas(this.numberFromCommas(item.total), "noFloat")
            .length
        ) {
          this.$nextTick(() => {
            this.$refs["total_price" + index][0]?.$el
              ? (this.$refs["total_price" + index][0].$el.querySelector(
                  "input"
                ).selectionEnd = pos)
              : "";
          });
        } else {
          this.$nextTick(() => {
            this.$refs["total_price" + index][0]?.$el
              ? (this.$refs["total_price" + index][0].$el.querySelector(
                  "input"
                ).selectionEnd = pos - 1)
              : "";
          });
        }
      }
      let unitPriceNumber = parseFloat(this.numberFromCommas(item.unit_price));
      this.creditInvoiceItems[index].unit_price = this.numberWithCommas(
        unitPriceNumber.toFixed(decimalPoints),
        "noFloat"
      );

      this.creditInvoiceItems[index].total = this.numberWithCommas(
        this.numberFromCommas(item.total),
        "noFloat"
      );
      this.$nextTick(() => {
        if (this.$refs.form && this.$refs["unit_price" + index][0].validate()) {
          item.unit_price = unitPriceNumber.toFixed(decimalPoints);
        }
        if (
          this.$refs.form &&
          this.$refs["total_price" + index][0].validate()
        ) {
          item.total = this.numberFromCommas(item.total);
        }
        if (parseFloat(item.unit_price)) {
          item.total =
            parseFloat(item.quantity) && parseFloat(item.unit_price)
              ? (
                  parseFloat(item.quantity) * parseFloat(item.unit_price)
                ).toFixed(2)
              : parseFloat(item.unit_price)
              ? parseFloat(item.unit_price).toFixed(2)
              : (0).toFixed(2);

          // item total calculation with discount
          item.total = parseFloat(item.line_discount)
            ? (
                parseFloat(item.total) -
                (parseFloat(item.total) * parseFloat(item.line_discount)) / 100
              ).toFixed(2)
            : parseFloat(item.total).toFixed(2);

          this.creditInvoiceItems[index].unit_price = this.numberWithCommas(
            unitPriceNumber.toFixed(decimalPoints),
            "noFloat"
          );

          this.creditInvoiceItems[index].total = this.numberWithCommas(
            this.numberFromCommas(item.total),
            "noFloat"
          );

          if (this.creditInvoiceItems[index].unit_price && !item.quantity) {
            item.quantity = 1;
          }
        } else {
          this.creditInvoiceItems[index].total = this.numberWithCommas(
            this.numberFromCommas(item.total),
            "noFloat"
          );
        }
        this.creditInvoiceItems[index] = item;

        // total calculation on quotation
        this.invoicePriceCalculation();
      });
    },
    grandTotalRequested() {
      if (this.model.grand_total && this.model.grand_total_requestd) {
        // after_discount and vat
        this.model.after_discount = (
          this.model.grand_total_requestd /
          (this.model.vat_percentage / 100 + 1)
        ).toFixed(2);
        this.model.vat = (
          this.model.grand_total_requestd - this.model.after_discount
        ).toFixed(2);
        // grand_total
        this.model.grand_total = this.model.grand_total_requestd;
        // discount and discount_percentage
        if (Math.abs(this.model.discount) < 1) {
          this.model.discount = 0.0;
          this.model.discount_percentage = "";
        } else {
          this.model.discount =
            parseFloat(this.model.total) -
            parseFloat(this.model.after_discount);
          this.model.discount_percentage = (
            (parseFloat(this.model.discount) / parseFloat(this.model.total)) *
            100
          ).toFixed(2);
        }
      } else {
        this.invoicePriceCalculation();
      }
    },
    invoicePriceCalculation() {
      let self = this;
      if (
        !this.$route.params.copiedModel &&
        !(this.$route.params.invoiceId || this.$route.query.returnCertificateId)
      ) {
        if (
          (!this.creditInvoiceId &&
            this.profileById.credit_invoice &&
            this.includeVatCharge) ||
          (this.creditInvoiceId && this.model.including_vat)
        ) {
          this.model.total =
            this.creditInvoiceItems.length > 0
              ? this.creditInvoiceItems
                  .reduce(function (total, item) {
                    return parseFloat(self.numberFromCommas(item.total))
                      ? parseFloat(total) +
                          parseFloat(self.numberFromCommas(item.total))
                      : total + 0;
                  }, 0)
                  .toFixed(2) /
                (self.model.vat_percentage / 100 + 1)
              : 0;
        } else if (
          this.$route.query.quotationId ||
          this.$route.query.proformaId ||
          this.$route.query.deliveryId ||
          this.$route.query.invoiceId ||
          this.$route.query.orderId ||
          this.$route.query.creditInvoiceId ||
          this.$route.query.returnCertificateId
        ) {
          if (
            this.quotationById.including_vat ||
            this.proformaById.including_vat ||
            this.deliveryById.including_vat ||
            this.invoiceById.including_vat ||
            this.orderById.including_vat ||
            this.creditInvoiceById.including_vat ||
            this.returnCertificateById.including_vat
          ) {
            this.model.total =
              this.creditInvoiceItems.length > 0
                ? this.creditInvoiceItems
                    .reduce(function (total, item) {
                      return parseFloat(self.numberFromCommas(item.total))
                        ? parseFloat(total) +
                            parseFloat(self.numberFromCommas(item.total))
                        : total + 0;
                    }, 0)
                    .toFixed(2) /
                  (self.model.vat_percentage / 100 + 1)
                : 0;
          } else {
            this.model.total =
              this.creditInvoiceItems.length > 0
                ? this.creditInvoiceItems
                    .reduce(function (total, item) {
                      return parseFloat(self.numberFromCommas(item.total))
                        ? parseFloat(total) +
                            parseFloat(self.numberFromCommas(item.total))
                        : total + 0;
                    }, 0)
                    .toFixed(2)
                : 0;
          }
        } else if (this.$route.query.receiptTaxInvoiceId) {
          if (this.receiptTaxInvoiceById.including_vat) {
            this.model.total =
              this.creditInvoiceItems.length > 0
                ? this.creditInvoiceItems
                    .reduce(function (total, item) {
                      return parseFloat(self.numberFromCommas(item.total))
                        ? parseFloat(total) +
                            parseFloat(self.numberFromCommas(item.total))
                        : total + 0;
                    }, 0)
                    .toFixed(2) /
                  (self.model.vat_percentage / 100 + 1)
                : 0;
          }
        } else {
          this.model.total =
            this.creditInvoiceItems.length > 0
              ? this.creditInvoiceItems
                  .reduce(function (total, item) {
                    return parseFloat(self.numberFromCommas(item.total))
                      ? parseFloat(total) +
                          parseFloat(self.numberFromCommas(item.total))
                      : total + 0;
                  }, 0)
                  .toFixed(2)
              : 0;
        }
      } else if (
        (this.$route.params.invoiceId ||
          this.$route.query.returnCertificateId) &&
        !this.$route.params.copiedModel
      ) {
        if (this.model.including_vat) {
          this.model.total =
            this.creditInvoiceItems.length > 0
              ? this.creditInvoiceItems
                  .reduce(function (total, item) {
                    return parseFloat(self.numberFromCommas(item.total))
                      ? parseFloat(total) +
                          parseFloat(self.numberFromCommas(item.total))
                      : total + 0;
                  }, 0)
                  .toFixed(2) /
                (self.model.vat_percentage / 100 + 1)
              : 0;
        } else {
          this.model.total =
            this.creditInvoiceItems.length > 0
              ? this.creditInvoiceItems
                  .reduce(function (total, item) {
                    return parseFloat(self.numberFromCommas(item.total))
                      ? parseFloat(total) +
                          parseFloat(self.numberFromCommas(item.total))
                      : total + 0;
                  }, 0)
                  .toFixed(2)
              : 0;
        }
      } else {
        if (this.model.including_vat) {
          this.model.total =
            this.creditInvoiceItems.length > 0
              ? this.creditInvoiceItems
                  .reduce(function (total, item) {
                    return parseFloat(self.numberFromCommas(item.total))
                      ? parseFloat(total) +
                          parseFloat(self.numberFromCommas(item.total))
                      : total + 0;
                  }, 0)
                  .toFixed(2) /
                (self.model.vat_percentage / 100 + 1)
              : 0;
        } else {
          this.model.total =
            this.creditInvoiceItems.length > 0
              ? this.creditInvoiceItems
                  .reduce(function (total, item) {
                    return parseFloat(self.numberFromCommas(item.total))
                      ? parseFloat(total) +
                          parseFloat(self.numberFromCommas(item.total))
                      : total + 0;
                  }, 0)
                  .toFixed(2)
              : 0;
        }
      }
      // discount amount calculation
      this.model.discount_percentage = parseFloat(
        this.model.discount_percentage
      )
        ? parseFloat(this.model.discount_percentage).toFixed(2)
        : null;
      this.model.discount = parseFloat(this.model.discount_percentage)
        ? (
            (parseFloat(this.model.total) *
              parseFloat(this.model.discount_percentage)) /
            100
          ).toFixed(2)
        : (0).toFixed(2);

      // total amount after discount calculation
      this.model.after_discount = parseFloat(this.model.discount)
        ? (
            parseFloat(this.model.total) - parseFloat(this.model.discount)
          ).toFixed(2)
        : parseFloat(this.model.total).toFixed(2);

      this.model.vat_percentage = parseFloat(this.model.vat_percentage)
        ? parseFloat(this.model.vat_percentage)
        : null;
      this.model.vat =
        parseFloat(this.model.vat_percentage) || this.includeVatCharge
          ? (
              (parseFloat(this.model.after_discount) *
                parseFloat(this.model.vat_percentage)) /
              100
            ).toFixed(2)
          : (0).toFixed(2);

      // grand total amount calculation
      this.model.grand_total = parseFloat(this.model.vat)
        ? (
            parseFloat(this.model.after_discount) + parseFloat(this.model.vat)
          ).toFixed(2)
        : parseFloat(this.model.after_discount).toFixed(2);

      // after vat grand total rounding if total rounding is true
      if (this.profileById.total_rounding) {
        let total = this.model.total;
        let discount = this.model.discount;
        let vatPercentage = this.model.vat_percentage;
        let grandTotal = parseInt(this.model.grand_total);
        let roundingDiff = this.model.grand_total - grandTotal;
        let findRoundNumber = parseFloat(
          roundingDiff / (1 + vatPercentage / 100)
        ).toFixed(2);
        if (findRoundNumber <= 0.5) {
          // calculate discount if findRoundNumber is lessthan 0.5
          this.model.discount = (
            parseFloat(discount) + parseFloat(findRoundNumber)
          ).toFixed(2);
          // calculate after_discount
          this.model.after_discount = (
            parseFloat(total) - parseFloat(this.model.discount)
          ).toFixed(2);
          // calculate vat
          this.model.vat = (
            parseFloat(grandTotal) - parseFloat(this.model.after_discount)
          ).toFixed(2);
          // calculate grand_total
          this.model.grand_total = parseFloat(grandTotal).toFixed(2);
        } else {
          let roundingDiff = parseFloat(
            (grandTotal + 1 - this.model.grand_total) * -1
          ).toFixed(2);
          // calculate discount if findRoundNumber is lessthan 0.5
          this.model.discount = (
            parseFloat(discount) +
            parseFloat(roundingDiff / (1 + vatPercentage / 100))
          ).toFixed(2);
          // calculate after_discount
          this.model.after_discount = (
            parseFloat(total) - parseFloat(this.model.discount)
          ).toFixed(2);
          // calculate vat
          this.model.vat = (
            parseFloat(grandTotal) +
            1 -
            parseFloat(this.model.after_discount)
          ).toFixed(2);
          // calculate grand_total
          this.model.grand_total = (parseFloat(grandTotal) + 1).toFixed(2);
        }
      }
    },
    addRow() {
      this.creditInvoiceItems.push({
        index: this.creditInvoiceItems.length,
        selectedProduct: null,
        unit_price: null,
        item_description: null,
        total: null,
      });
      // scroll on new added row
      let newRow = document.querySelector(".newRow");
      let wrapper = document.querySelector(
        ".item-table div.v-data-table__wrapper"
      );
      this.$vuetify.goTo(newRow, { container: wrapper });
    },
    copyData(i) {
      delete this.creditInvoiceItems[i].id;
      delete this.creditInvoiceItems[i].credit_invoice_id;
      delete this.creditInvoiceItems[i].created_at;
      delete this.creditInvoiceItems[i].updated_at;
      delete this.creditInvoiceItems[i].year;
      delete this.creditInvoiceItems[i].line_number;
      delete this.creditInvoiceItems[i].supplied;
      this.creditInvoiceItems.push({ ...this.creditInvoiceItems[i] });
    },
    deleteRow(index) {
      this.creditInvoiceItems.splice(index, 1);
      this.invoicePriceCalculation();
    },
    async onProductChange(item, index, type) {
      if (item && type === "dropdown") {
        this.creditInvoiceItems[index].index = index;
        this.creditInvoiceItems[index].selectedProduct = {
          item_id: item.id,
          item_code: item.item_code,
        };

        if (!item?.customerPrice) {
          this.creditInvoiceItems[index].unit_price = item.unit_price;
        }

        this.creditInvoiceItems[index].item_id = item.id;
        this.creditInvoiceItems[index].quantity = item.quantity;

        if (item.quantity) {
          if (this.selectedCustomer?.fixed_discount) {
            this.creditInvoiceItems[index].line_discount =
              this.selectedCustomer?.fixed_discount;
          } else if (item.customerDiscount) {
            this.creditInvoiceItems[index].line_discount =
              item.customerDiscount;
          } else if (item.customerPrice) {
            this.creditInvoiceItems[index].unit_price = item.customerPrice;
          } else if (item.priceListDiscount) {
            this.creditInvoiceItems[index].line_discount =
              item.priceListDiscount;
          } else if (item.fixed_discount) {
            this.creditInvoiceItems[index].line_discount = item.fixed_discount;
          } else {
            this.creditInvoiceItems[index].line_discount = null;
          }
        } else {
          this.creditInvoiceItems[index].line_discount = null;
        }

        this.creditInvoiceItems[index].item_code = item.item_code;
        this.creditInvoiceItems[index].item_description = item.item_description;
        this.$refs.form.validate();
        this.itemPriceCalculation(index);
      } else {
        if (item && Object.keys(item).length > 0) {
          await this.$store
            .dispatch("product/GetItemDiscountOrPrice", {
              customer_id: this.selectedCustomer.id,
              item_id: item.id,
            })
            .then(async (res) => {
              this.itemDiscount = await res;
            });

          this.creditInvoiceItems[index].index = index;
          this.creditInvoiceItems[index].selectedProduct = {
            item_id: item.id,
            item_code: item.item_code,
          };

          if (!this.itemDiscount && !this.itemDiscount.customerPrice) {
            this.creditInvoiceItems[index].unit_price = item.sale_price;
          }

          this.creditInvoiceItems[index].item_id = item.id;
          this.creditInvoiceItems[index].quantity = item.quantity;

          if (this.selectedCustomer?.fixed_discount) {
            this.creditInvoiceItems[index].line_discount =
              this.selectedCustomer?.fixed_discount;
          } else if (this.itemDiscount && this.itemDiscount.customerDiscount) {
            this.creditInvoiceItems[index].line_discount =
              this.itemDiscount.customerDiscount;
          } else if (this.itemDiscount && this.itemDiscount.customerPrice) {
            this.creditInvoiceItems[index].unit_price =
              this.itemDiscount.customerPrice;
          } else if (this.itemDiscount && this.itemDiscount.priceListDiscount) {
            this.creditInvoiceItems[index].line_discount =
              this.itemDiscount.priceListDiscount;
          } else {
            this.creditInvoiceItems[index].line_discount = null;
          }

          this.creditInvoiceItems[index].item_code = item.item_code;
          this.creditInvoiceItems[index].item_description = item.description;
          this.$refs.form.validate();
          this.itemPriceCalculation(index);
        } else {
          this.creditInvoiceItems[index].index = index;
          this.creditInvoiceItems[index].selectedProduct = null;
          this.creditInvoiceItems[index].unit_price = null;
          this.creditInvoiceItems[index].item_id = null;
          this.creditInvoiceItems[index].quantity = null;
          this.creditInvoiceItems[index].line_discount = null;
          this.creditInvoiceItems[index].item_code = null;
          this.creditInvoiceItems[index].item_description = null;
          this.$refs.form.validate();
          this.itemPriceCalculation(index);
        }
      }
    },
    getInvoices(type) {
      if (!type && this.$refs.form.validate()) {
        this.creditInvoicePaymentsType = type;
        this.creditInvoicePaymentsDialog = true;
      } else if (type) {
        this.type = type;
        this.creditInvoicePaymentsType = type;
        this.creditInvoicePaymentsDialog = true;
      }
    },
    async saveInvoice() {
      if (
        this.$refs.form.validate() ||
        (this.$refs.form.validate() &&
          this.isFieldReadable &&
          this.saveOnlyCategoryStatus)
      ) {
        this.loading = true;
        let data;
        let creditInvoiceItems;
        // if (this.creditInvoiceItems.length > 0) {
        creditInvoiceItems = this.creditInvoiceItems.filter((item) => {
          if (item.id || item.item_id) {
            delete item["selectedProduct"];
            delete item["index"];
            delete item["year"];
            delete item["document_number"];
            delete item["document_date"];
            delete item["customer_order_no"];
            delete item["title"];
            item.quantity = item.quantity === "" ? null : item.quantity;
            item.line_discount =
              item.line_discount === "" ? null : item.line_discount;
            item.unit_price =
              this.numberFromCommas(item.unit_price) === ""
                ? null
                : this.numberFromCommas(item.unit_price);
            item.total =
              this.numberFromCommas(item.total) === ""
                ? null
                : this.numberFromCommas(item.total);
            return item;
          }
        });
        // }
        this.model.creditInvoiceItems = creditInvoiceItems;
        if (this.$route.query.returnCertificateId) {
          delete this.model.year;
          delete this.model.return_certificate_no;
          this.model.is_invoiced = false;
          delete this.model.return_certificate_document_number;
          delete this.model.returnCertificateStatus;
        }
        if (
          this.selectedStatus &&
          Object.keys(this.selectedStatus).length > 0
        ) {
          this.model.status = this.selectedStatus.id;
        } else {
          this.model.status = this.selectedStatus;
        }
        if (this.selectedGroup && Object.keys(this.selectedGroup).length > 0) {
          this.model.status_one = this.selectedGroup.id;
        }
        if (
          this.selectedWarehouse &&
          Object.keys(this.selectedWarehouse).length > 0
        ) {
          this.model.warehouse = this.selectedWarehouse.id;
        }
        if (this.$route.params.invoiceId && !this.model.id) {
          this.model.including_vat = this.model.including_vat ? 1 : 0;
        } else if (this.$route.query.returnCertificateId && !this.model.id) {
          this.model.including_vat = this.model.including_vat ? 1 : 0;
        } else if (!this.model.id) {
          this.model.including_vat = this.profileById.credit_invoice ? 1 : 0;
        }
        if (this.$route.params.copiedModel && !this.model.id) {
          this.model.including_vat = this.originModel.including_vat ? 1 : 0;
        }
        if (!this.model.id) {
          if (this.$route.query.quotationId) {
            this.model.including_vat = this.quotationById.including_vat ? 1 : 0;
          } else if (this.$route.query.proformaId) {
            this.model.including_vat = this.proformaById.including_vat ? 1 : 0;
          } else if (this.$route.query.deliveryId) {
            this.model.including_vat = this.deliveryById.including_vat ? 1 : 0;
          } else if (this.$route.query.invoiceId) {
            this.model.including_vat = this.invoiceById.including_vat ? 1 : 0;
          } else if (this.$route.query.receiptTaxInvoiceId) {
            this.model.including_vat = this.receiptTaxInvoiceById.including_vat
              ? 1
              : 0;
          } else if (this.$route.query.orderId) {
            this.model.including_vat = this.orderById.including_vat ? 1 : 0;
          } else if (this.$route.query.returnCertificateId) {
            this.model.including_vat = this.returnCertificateById.including_vat
              ? 1
              : 0;
          }
        }
        if (this.moreDocumentNumber && !this.creditInvoiceId) {
          this.model.doc_series_number = this.model.doc_series_number.id;
        }
        this.model.main_number = 41;
        if (this.$route.query.type !== "copy-create") {
          this.model.return_certificate_id =
            this.$route.query.returnCertificateId;
        }

        // if(this.model.creditInvoiceItems.length > 0) {
        if (this.selectedContact && typeof this.selectedContact === "string") {
          if (this.model.customer_id) {
            let contactData = await this.$store.dispatch("contact/SetContact", {
              name: this.selectedContact,
              record_type: "customers",
              record_type_number: this.model.customer_id,
            });
            this.model.contact_id = contactData.data.id;
          }
        }
        if (
          this.model.creditInvoicePaymentsData &&
          this.model.creditInvoicePaymentsData.length > 0
        ) {
          this.model.creditInvoicePaymentsData.filter((item) => {
            item.amount_paid =
              this.numberFromCommas(item.amount_paid) === ""
                ? null
                : this.numberFromCommas(item.amount_paid);
            return item;
          });
        }
        delete this.model.creditInvoicePayments;
        if (this.$route.params.credit_invoice_id) {
          // save only update model value using changedFormData from utils
          let saveData = changedFormData(this.model, this.originModel);
          saveData.credit_invoice_date = parseDateYYYYMMDD(
            this.model.credit_invoice_date
          );
          this.isProgressCircular = true;
          data = await this.$store
            .dispatch("creditInvoice/EditCreditInvoice", {
              credit_invoice_id: this.$route.params.credit_invoice_id,
              creditInvoice: saveData,
            })
            .catch((e) => {
              this.loading = false;
              console.log(e);
            });
          if (data) {
            this.$refs.form.reset();
            this.loading = false;
            this.creditInvoicePaymentsType = null;
            this.creditInvoicePaymentsModel = null;
            this.creditInvoicePaymentsDialog = false;
            this.paidTotal = null;
            this.updatedDocuments = null;
            await this.onMounteUpdated();
          }
          this.isProgressCircular = false;
        } else {
          this.model?.creditInvoiceItems.forEach((ele) => {
            delete ele.remainingQuantity;
            delete ele.id;
          });
          data = await this.$store
            .dispatch("creditInvoice/SetCreditInvoice", this.model)
            .catch((e) => {
              this.loading = false;
              console.log(e);
            });
          if (data) {
            this.$refs.form.reset();
            this.loading = false;
            if (this.accessRight.includes("edit")) {
              await this.$router
                .push("/creditInvoice/editcreditInvoice/" + data.data.id)
                .catch(() => {});
            } else {
              this.callBackFunction();
            }
          }
        }
        // }
        // else {
        //   this.message = "formRules.productItemAlert";
        //   this.alert = true;
        //   this.loading = false;
        // }
      }
      // else {
      //   this.message = "formRules.formAlert";
      //   this.alert = true;
      // }
    },
    async deleteItem() {
      this.isProgressCircular = true;
      await this.$store.dispatch(
        "creditInvoice/DeleteCreditInvoiceItemById",
        this.$route.params.credit_invoice_id
      );
      await this.$router
        .push(
          "/creditInvoice/editcreditInvoice/" +
            this.$route.params.credit_invoice_id
        )
        .catch(() => {});
      this.model.status = 2;
      this.originModel.status = 2;
      this.selectedStatus = 2;
      this.isProgressCircular = false;
    },
  },
};
</script>

<style scoped>
::v-deep .custom-table ::-webkit-scrollbar {
  width: 12px;
}
::v-deep .yourRowClass.disabled {
  pointer-events: none;
  opacity: 0.5;
}
</style>
